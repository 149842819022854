import React,{useState,useEffect} from 'react'
import '../CSS/DealerPageWithSideMenu.css'
import { Nav,Tab } from 'react-bootstrap';
import Logo from '../Images/LoginImages/tdaak-logo.png'
import RupeesPng from '../Images/img/icons8-rupee-24.png'
import PaymentPng from '../Images/img/icons8-payment-50.png'
import CouponsPng from '../Images/img/icons8-coupons-58.png'
import {  useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BaseUrl } from '../Constants/BaseUrl';
import axios from 'axios';
import { Carousel } from 'react-bootstrap';
import PriceProgressBar from '../Components/PriceProgressBar';
import ReportPng from '../Images/img/report.png'
import CouponHistoryDealer from '../Components/CouponHistoryDealer';
import LogoutPng from '../Images/img/logout.png'
import PaymentHistoryDealer from '../Components/PaymentHistoryDealer';
function DealerPageWithSideMenu() {
    //Refresh
    const [startY, setStartY] = useState(0);
    const [refreshThreshold, setRefreshThreshold] = useState(150);
    const [isRefreshing, setIsRefreshing] = useState(false);
  
    useEffect(() => {
      const handleTouchStart = (event) => {
        setStartY(event.touches[0].clientY);
      };
  
      const handleTouchEnd = (event) => {
        const deltaY = event.changedTouches[0].clientY - startY;
        if (deltaY > refreshThreshold) {
          setIsRefreshing(true);
          window.location.reload();
        }
      };
  
      document.addEventListener('touchstart', handleTouchStart);
      document.addEventListener('touchend', handleTouchEnd);
  
      return () => {
        document.removeEventListener('touchstart', handleTouchStart);
        document.removeEventListener('touchend', handleTouchEnd);
      };
    }, [refreshThreshold, startY]);
  
    /////////////
  const navigate=useNavigate()
    const [activeTab, setActiveTab] = useState('request-coupons');
    const handleTabSelect = tab => {
      setActiveTab(tab);
    };
    const [sidebarVisible, setSidebarVisible] = useState(false);
    
  function handleToggleSidebar() {
    setSidebarVisible(!sidebarVisible);
  }
  const user = JSON.parse(localStorage.getItem('user'))
  const dealerid=user.id

  // const dealerid='67999EC3-FD99-47EF-B352-152BD74623EF'
  const [PaymentData, setPaymentData] = useState([])
  const [LastReqDate,setLastReqDate]=useState('')
  const [CouponNumber, setCouponNumber] = useState('')
  const [IsFinished, setIsFinished] = useState(true)
  const [PaymentModeData, setPaymentModeData] = useState([])
const [CouponRequestButton, setCouponRequestButton] = useState(false)
  const toggleIsFinished = () => {
    setIsFinished(!IsFinished);
  };
  //calculate coupon in hand
  const [CouponInHand, setCouponInHand] = useState(0)
function calculateRowCountSum(array) {
  let sum = 0;
  for (let i = 0; i < array.length; i++) {
    if (typeof array[i].RowCount === 'number') {
      sum += array[i].RowCount;
    }
  }
  setCouponInHand(sum)
}
  useEffect(() => {
      //payment history
      axios.get(`${BaseUrl}/getpaymentaccounts?dealerid=${dealerid}`)
      .then((res) => {
     console.log(res.data)
     setPaymentData(res.data)
      })
      .catch((error) => console.log(error)); //hard coded
      
      //Get payment Mode
      axios.get(`${BaseUrl}/getpaymode`)
      .then((res) => {
        const filteredArray = res.data.filter(obj => obj.Mode !== 'District')
   setPaymentModeData(filteredArray)    
      })
      .catch((error) => console.log(error))
    //coupon In hand
    axios.get(`${BaseUrl}/getdealercoupondash?did=${dealerid}`)
    .then((res) => {
      if (res.data) {
        console.log(res.data);
        calculateRowCountSum(res.data)
      }
    })
    .catch((error) => console.log(error)); 
  }, [])
useEffect(() => {
  axios.get(`${BaseUrl}/lastreqdate?did=${dealerid}`)
  .then((res) => {
 setLastReqDate(res.data[0].RequestDate)
  })
  .catch((error) => console.log(error));
}, [IsFinished])

function Request() {
  const RequestCouponData = { number: CouponNumber, dealerid };
  
  if (CouponNumber <= 0 || !Number.isInteger(CouponNumber)) {
    alert('Please enter a valid positive natural number for the number of books');
  } else {
    setCouponRequestButton(true)
    axios.post(BaseUrl + '/setdealerrequest', RequestCouponData)
      .then(response => {
        console.log(response.data);
        toggleIsFinished();
        toast.success('Request successful!', {
          position: 'top-center' // Set the position to top-center
        });
        setCouponNumber('');
      })
      .catch(error => {
        console.log(error);
        toast.error('An error occurred.', {
          position: 'top-center' // Set the position to top-center
        });
      }).finally(() => {
        setCouponRequestButton(false)
      });
  }
}


  //date conversion
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    return `${formattedDay}/${formattedMonth}/${year}`;
  }
  //payment total
  // Calculate the total amount
const totalAmount = PaymentData.reduce((total, data) => {
  return data.Status === 0 ? total - data.amount : total + data.amount;
}, 0);
const result = totalAmount > 0 ? 0 : Math.abs(totalAmount);

// Determine the color for "Amount to be paid"
const amountColor = totalAmount < 0 ? 'red' : 'green';

//Payment Request
const [PaymentDate, setPaymentDate] = useState(new Date().toISOString().split('T')[0])
const [PaymentModeId, setPaymentModeId] = useState('default')
const [PaymentAmount, setPaymentAmount] = useState('')
const [TransactionId, setTransactionId] = useState('')
const [PaymentButton, setPaymentButton] = useState(false)
function RequstPayment() {
  const paymentDetails = {
    dealerid: dealerid,
    pdate: PaymentDate,
    amount: PaymentAmount,
    status: 0,
    paymode: PaymentModeId,
    transactionid:TransactionId
  };

  if (PaymentModeId === 'default') {
    alert('Please select a Payment Mode');
  } else if (!Number.isInteger(PaymentAmount) || PaymentAmount <= 0) {
    alert('Please enter a valid positive natural number');
  }else if((Math.abs(totalAmount)-PaymentAmount)<0){
    console.log(Math.abs(totalAmount)-PaymentAmount)
    alert(`Your payment pending amount is ₹${Math.abs(totalAmount)}, but you entered an amount that exceeds it.`)
  } else {
    setPaymentButton(true)
    axios.post(BaseUrl + '/setpayment', paymentDetails)
      .then(response => {
        toast.success('Payment request success!', {
          position: 'top-center' // Set the position to top-center
        });
        setPaymentAmount('')
        setPaymentModeId('default')
        setPaymentDate(new Date().toISOString().split('T')[0])
        setTransactionId('')
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        // Enable the button again after the request is completed
        setPaymentButton(false);
      });
  }
}


//style
const styles = {
  brown: '#786450',
  frame: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '400px',
    height: '400px',
    marginTop: '-200px',
    marginLeft: '-200px',
    borderRadius: '2px',
    boxShadow: '1px 2px 10px 0px rgba(0,0,0,0.3)',
    background: '#CA7C4E',
    background: '-webkit-linear-gradient(bottom left, #EEBE6C 0%, #CA7C4E 100%)',
    background: '-moz-linear-gradient(bottom left, #EEBE6C 0%, #CA7C4E 100%)',
    background: '-o-linear-gradient(bottom left, #EEBE6C 0%, #CA7C4E 100%)',
    background: 'linear-gradient(to top right, #EEBE6C 0%, #CA7C4E 100%)',
    filter: 'progid:DXImageTransform.Microsoft.gradient(startColorstr="#EEBE6C", endColorstr="#CA7C4E", GradientType=1)',
    color: '#786450',
    fontFamily: "'Open Sans', Helvetica, sans-serif",
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  center: {
    position: 'fixed',
    top: '56px',
    right: '25px',
    height: '325px',
    width: '200px',
    background: '#fff',
    border: '5px solid #92580a',
    borderRadius: '10px',
    overflow: 'hidden',
    boxShadow: '10px 10px 15px 0 rgba(0, 0, 0, 0.3)',
    zIndex: 1025
  },
  profile: {
    float: 'left',
    width: '192px',
    height: '320px',
    textAlign: 'center',
  },
  image: {
    position: 'relative',
    width: '70px',
    height: '70px',
    margin: '6px auto 0 auto',
  },
  circle1: {
    position: 'absolute',
    boxSizing: 'border-box',
    width: '76px',
    height: '76px',
    top: '-3px',
    left: '-3px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#786450 #786450 #786450 transparent',
    borderRadius: '50%',
    transition: 'all 1.5s ease-in-out',
  },
  circle2: {
    extend: 'circle1',
    width: '82px',
    height: '82px',
    top: '-6px',
    left: '-6px',
    borderColor: '#786450 transparent #786450 #786450',
  },
  img: {
    display: 'block',
  },
  name: {
    fontSize: '20px',
    fontWeight: '700',
    marginTop: '5px',
  },
  job: {
    fontSize: '15px',
    lineHeight: '15px',
  },
  actions: {
    marginTop: '25px',
  },
  btn: {
    display: 'block',
    width: '145px',
    height: '35px',
    margin: '0 auto 10px auto',
    background: 'none',
    border: '1px solid #786450',
    borderRadius: '15px',
    fontSize: '14px',
    fontWeight: '700',
    boxSizing: 'border-box',
    transition: 'all .3s ease-in-out',
    color: 'red',
  },
  stats: {
    float: 'left',
  },
  box: {
    boxSizing: 'border-box',
    width: '120px',
    height: '99px',
    background: '#F5E8DF',
    textAlign: 'center',
    paddingTop: '28px',
    transition: 'all .4s ease-in-out',
  },
  boxHover: {
    background: '#E1CFC2',
    cursor: 'pointer',
  },
  box2: {
    margin: '1px 0',
  },
  value: {
    fontSize: '18px',
    fontWeight: '600',
  },
  parameter: {
    fontSize: '11px',
  },

  rowStyles: {
    '--bs-gutter-x': 'initial' // Set the property to 'initial' to remove it
  }
};

//Logout
function Logout() {
  const confirmMessage = "Are you sure you want to logout?"

  if (window.confirm(confirmMessage)) {
  localStorage.removeItem('isLoggedIn');
  localStorage.removeItem('userRole');
  localStorage.removeItem('user')
  localStorage.removeItem('DistrictId')
  navigate('/')
  }
}
//Account Delete
function DeleteAccount() {
  const confirmMessage = "Are you sure you want to delete your account?";

  if (window.confirm(confirmMessage)) {
    axios.post(BaseUrl + `/DeleteDealerAccount?DealerId=${dealerid}`)
      .then(response => {
        if(response.data){
          localStorage.removeItem('isLoggedIn');
          localStorage.removeItem('userRole');
          localStorage.removeItem('user')
          navigate('/')
        }
      })
      .catch(error => {
        console.log(error);
      });
  } else {
    console.log("Account deletion cancelled");
  }
}
  return (
<div className="bodybg cv-bodypadding">
<div className="header-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-5">
              <img src={Logo} className="cv-reg-taglahlogo" alt="SaveDate" />
            </div>
            <div className="col-lg-6 col-md-6 col-7">
            {/* <button className="notification-bell" onClick={Logout}><i class="fa-solid fa-right-from-bracket"></i></button> */}
              <button className="notification-bell" onClick={handleToggleSidebar}><i id="menubtn" className={`fas ${sidebarVisible ? 'fa-times' : 'fa-user-circle'}`}></i></button>
            </div>
          </div>
        </div>
      </div>
      {sidebarVisible && (
      <div style={styles.center}>
        <div style={styles.profile}>
          <div style={styles.image}>
            <div style={styles.circle2}>
            <img
              src={Logo}
              width="70"
              height="60"
              alt="TDAAK"
              style={styles.img}
            />
            </div>
          </div>
          <div style={styles.name}>{user.Name}</div>
          <div style={styles.job}>{user.district}</div>
          <div style={styles.actions}>
          {user.usertype=='F4688F9E-702F-4147-A44A-99866FE9124A'&&<button style={{ ...styles.btn, color: 'white',backgroundColor:'#064185' }} onClick={()=>navigate('/district')} >
          <i class="fa-solid fa-right-left" style={{ marginRight: '8px' }}></i>
            District Page</button>}
           {user.usertype=='BC763517-EE09-4DFB-8571-64E5E3EF7B64'&&<button style={{ ...styles.btn, color: 'white',backgroundColor:'#064185' }} onClick={()=>navigate('/statecommitte')} >
           <i class="fa-solid fa-right-left" style={{ marginRight: '8px' }}></i>
            State page</button> }
            <button style={{ ...styles.btn, color: 'white',background:'#dc1818' }} onClick={Logout}>
          <i class="fa-solid fa-right-from-bracket"  style={{ marginRight: '8px' }}></i>
            Logout</button>
                      {/* <button style={{ ...styles.btn, color: 'white',background:'#dc1818' }} onClick={DeleteAccount}>
      <i className="fas fa-trash-alt" style={{ marginRight: '8px' }}></i>
      Delete Account
    </button> */}
    <div style={{padding:'42px',fontSize:'10px'}}>
        <label onClick={DeleteAccount}>Delete Account</label>
        </div>
          </div>
        </div>
      </div>
      )}
  <Tab.Container activeKey={activeTab} onSelect={handleTabSelect} >
    <div className="savedate-sidebar">
      <Nav className="flex-column "  fill variant="pills" id="v-pills-tab" aria-orientation="vertical" >
        <Nav.Link  eventKey="request-coupons" style={{ textAlign: 'left' }}>  
        <span style={{backgroundImage: `url(${CouponsPng})`,backgroundSize: 'cover', backgroundPosition: 'center'}}></span>
        <h6>Request books</h6>
        </Nav.Link>

        <Nav.Link eventKey="payment-history" style={{ textAlign: 'left' }}>  
        <span className="groom" style={{backgroundImage: `url(${RupeesPng})`,backgroundSize: 'cover', backgroundPosition: 'center'}}></span>
        <h6>Payment Ledger</h6>
        </Nav.Link>
        <Nav.Link eventKey="payment-request" style={{ textAlign: 'left' }}>  
        <span className="appearance" style={{backgroundImage: `url(${PaymentPng})`,backgroundSize: 'cover', backgroundPosition: 'center'}}></span>
        <h6>Payment Intimation</h6>
        </Nav.Link>
        <Nav.Link eventKey="Coupon-history" style={{ textAlign: 'left' }}>  
        <span className="appearance" style={{backgroundImage: `url(${ReportPng})`,backgroundSize: 'cover', backgroundPosition: 'center'}}></span>
        <h6>Books History</h6>
        </Nav.Link>
        <Nav.Link eventKey="Payment-history" style={{ textAlign: 'left' }}>  
        <span className="appearance" style={{backgroundImage: `url(${ReportPng})`,backgroundSize: 'cover', backgroundPosition: 'center'}}></span>
        <h6>Payment History</h6>
        </Nav.Link>
      </Nav>
      </div>
      <div className="container-fluid">
      <Tab.Content id="v-pills-tabContent">
        <Tab.Pane eventKey="request-coupons" style={{marginTop:'60px'}}>
         <div className='container'>
         <div className="row" style={styles.rowStyles}>
  <div className="col-4 mb-4">
    <div className="card">
      <span className="big">{CouponInHand}</span>
      <span className="small">Books in hand</span>
    </div>
  </div>
  <div className="col-4 mb-4">
    <div className="card">
      <span className="big" style={{color:amountColor}}>₹{result}</span>
      <span className="small">Payment Pending</span>
    </div>
  </div>
  <div className="col-4 mb-4">
    <div className="card">
      <span className="big">{user.target}</span>
      <span className="small">Commitment</span>
    </div>
  </div>
        </div>
  <div className='row'>
  <div className="col-12  mb-4 ">
         <div  style={{ background: 'rgb(245, 232, 223)', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '300px', border: '5px solid #92580a',borderRadius:'20px'}}>
         <div style={{padding:'10px'}}>
         <h4>
                Request New Books
         </h4>
        {LastReqDate.length>0&&<h6 style={{fontWeight:'2px', color: 'red' }}>(Last requested on {formatDate(LastReqDate)})</h6>}
         </div>
        <input
        type="number"
        placeholder="Enter number of Books"
        value={CouponNumber}
        style={{ width: '250px',minHeight:'40px' ,textAlign: 'center',borderRadius:'20px'}}
        onChange={(e) => setCouponNumber(parseFloat(e.target.value))}/>
               <div  style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
              <button className="issue-button" style={{width:'115px',height:'40px'}} onClick={Request} disabled={CouponRequestButton}> {CouponRequestButton ? 'Processing...' : 'Request'}</button>
            </div>
  </div>
         </div>
  </div>
        </div>
        <PriceProgressBar CouponInHand={CouponInHand}/>
        </Tab.Pane>
        <Tab.Pane eventKey="payment-history" style={{marginTop:'60px'}}>
        <div className="row" style={styles.rowStyles}>
  <div className="col-4 mb-4">
    <div className="card">
      <span className="big">{CouponInHand}</span>
      <span className="small">Books in Hand</span>
    </div>
  </div>
  <div className="col-4 mb-4">
    <div className="card">
      <span className="big" style={{color:amountColor}}>₹{result}</span>
      <span className="small">Payment pending</span>
    </div>
  </div>
  <div className="col-4 mb-4">
    <div className="card">
      <span className="big">{user.target}</span>
      <span className="small">Commitment</span>
    </div>
  </div>
       </div>
        <div className="col-lg-12">
        <div className="text-center">
            <h3 style={{padding:'10px'}} >Payment Ledger</h3>
          </div>
          <table className="table table-bordered table-responsive">
            <thead style={{background:'#064185',color:'White'}}>
              <tr>
                <th className="text-center">Date</th>
                <th className="text-center">Particulars</th>
                <th className="text-center">Amount</th>
              </tr>
            </thead>
            <tbody>
            {PaymentData.map((data, index) => (
  <tr key={index}>
    <td className="text-center">{formatDate(data.Date)}</td>
    <td className="text-center">{data.Particulars}</td>
    <td className="text-center" style={{ color: data.Status === 0 ? 'red' : 'green' }}>
    {data.Status === 0 ? '-' : '+'}₹{data.amount}
    </td>
  </tr>
))}
<tr>
  <td className="text-center"></td>
 {totalAmount<=0?<td className="text-center"><strong>Amount to be paid</strong></td>:<td className="text-center"><strong>extra amount paid</strong></td>}
  <td className="text-center" style={{ color: amountColor }}>
    <strong>₹{Math.abs(totalAmount)}</strong>
  </td>
</tr>
</tbody>

          </table>
        </div>
        </Tab.Pane>
        <Tab.Pane eventKey="Coupon-history" style={{marginTop:'60px'}}>
            <CouponHistoryDealer DealerId={dealerid}/>
        </Tab.Pane>
        <Tab.Pane eventKey="Payment-history" style={{marginTop:'60px'}}>
            <PaymentHistoryDealer DealerId={dealerid}/>
        </Tab.Pane>
        <Tab.Pane eventKey="payment-request" style={{marginTop:'60px'}}>
        <div className="row" style={styles.rowStyles}>
  <div className="col-4">
    <div className="card">
      <span className="big">{CouponInHand}</span>
      <span className="small">Books in hand</span>
    </div>
  </div>
  <div className="col-4 ">
    <div className="card">
      <span className="big" style={{color:amountColor}}>₹{result}</span>
      <span className="small">Payment Pending</span>
    </div>
  </div>
  <div className="col-4">
    <div className="card">
      <span className="big">{user.target}</span>
      <span className="small">Commitment</span>
    </div>
  </div>
</div>
        <div className="requestcoupon" style={{ border: '5px solid #92580a',borderRadius:'20px'}}>
            <div className="row" style={{padding:'10px'}}>
              <h4>
                Payment Intimation
              </h4>
            </div>
            <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
           <input type="date" style={{ width: '250px',minHeight:'40px' ,textAlign: 'center',borderRadius:'20px' }} value={PaymentDate} onChange={(e) => setPaymentDate(e.target.value)} />
            </div>
            <div className="row" style={{ display: 'flex', justifyContent: 'center', padding:'10px' }}>
            <select name="paymentMethod"  value={PaymentModeId} onChange={(e) => setPaymentModeId(e.target.value)} style={{ width: '250px',minHeight:'40px' ,textAlign: 'center',borderRadius:'20px' }}>
            <option  value="default" disabled>Select payment mode</option>
            {PaymentModeData.map((data) => (<option key={data.Id} value={data.Id}>{data.Mode}</option>))}
          </select>
          </div>
         {PaymentModeId==2&&<div className="row" style={{ display: 'flex', justifyContent: 'center'  }}>
           <input type="text" style={{ width: '250px',minHeight:'40px' ,textAlign: 'center',borderRadius:'20px' }} placeholder='Transaction id or Bank Name' value={TransactionId} onChange={(e) => setTransactionId(e.target.value)}/>
            </div>}
            <div className="row" style={{ display: 'flex', justifyContent: 'center',padding:'10px'  }}>
              <input
                type="number"
                value={PaymentAmount}
                placeholder="Enter Amount(₹)"
                style={{ width: '250px',minHeight:'40px' ,textAlign: 'center',borderRadius:'20px' }}
                onChange={(e) => setPaymentAmount(parseFloat(e.target.value))}
              />
              
            </div>
            <div className="row" style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
              <button className="issue-button" style={{width:'115px',height:'40px'}} disabled={PaymentButton}  onClick={RequstPayment}>{PaymentButton? 'Processing...' : 'Intimate'}</button>
            </div>
          </div>
          {/* <div className='row'>
  <Carousel fade interval={3000} pause={false}>
    <Carousel.Item>
      <img src={PriceDetails} alt="tdaak" style={{ width: '100%', marginTop: '20px',height:'300px' }} />
    </Carousel.Item>
    <Carousel.Item>
      <img src={SampleBanner} alt="tdaak" style={{ width: '100%', marginTop: '20px',height:'300px'  }} />
    </Carousel.Item>
  </Carousel>

</div> */}
<div className='row mt-4'>
<PriceProgressBar CouponInHand={CouponInHand}/>
</div>
        </Tab.Pane>  
      </Tab.Content>
      </div>
    </Tab.Container>
</div>
  
  )
}

export default DealerPageWithSideMenu