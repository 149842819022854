import axios from 'axios';
import React,{useState,useEffect} from 'react'
import { BaseUrl } from '../Constants/BaseUrl';

function PaymentHistoryDealer(props) {
    const [PaymentHistoryData, setPaymentHistoryData] = useState([])
    function getStatusText(status,Mode,transactionid) {
        switch (status) {
          case 0:
            return ` ${Mode} payment intimation Pending..`;
          case 2:
            return `${Mode} payment intimation approved`;
          case 1:
            return `${Mode} payment intimation rejected`;
          default:
            return 'Unknown';
        }
      }
      function getStatusColor(status) {
        switch (status) {
          case 0:
            return 'blue';
          case 2:
            return 'green';
          case 1:
            return 'red';
          default:
            return 'black'; // Default color if status is unknown or not found in switch cases
        }
      }
      function formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedMonth = month < 10 ? `0${month}` : month;
        return `${formattedDay}/${formattedMonth}/${year}`;
      }
     useEffect(() => {
      axios.get(`${BaseUrl}/getpaymentmemberwise?memberid=${props.DealerId}`)
      .then((res) => {
          setPaymentHistoryData(res.data)
          console.log(res.data)
      })
      .catch((error) => console.log(error));
     }, [props.DealerId])
     if (!Array.isArray(PaymentHistoryData)) {
      return (
        <div className="col-lg-12">
          <div className="text-center">
            <h3 style={{ padding: '10px' }}>Payment History</h3>
          </div>
          <p className="text-center">Loading...</p>
        </div>
      );
    }
  return (
    <div className="col-lg-12">
    <div className="text-center">
      <h3 style={{ padding: '10px' }}>Payment History</h3>
    </div>
    <table className="table table-bordered table-responsive">
      <thead style={{ background: '#064185', color: 'White' }}>
        <tr>
          <th className="text-center">Date</th>
          <th className="text-center">Amount</th>
          <th className="text-center">Status</th>
        </tr>
      </thead>
      <tbody>
        {PaymentHistoryData.length === 0 ? (
          <tr>
            <td colSpan="3" className="text-center">
              No data available
            </td>
          </tr>
        ) : (
          PaymentHistoryData.map((data, index) => (
            <tr key={index}>
              <td className="text-center">{formatDate(data.PaymentDate)}</td>
              <td className="text-center" style={{ fontWeight: '700' }}>
                {data.Amount}
              </td>
              <td
                className="text-center"
                style={{
                  color: getStatusColor(data.Status),
                  fontWeight: '700',
                }}
              >
                {getStatusText(data.Status, data.ModeName, data.transactionid)}
              </td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  </div>
  )
}

export default PaymentHistoryDealer