import React from 'react'
import '../CSS/RequestedCouponDistrict.css'
function RequstedCouponDistrict2() {
    return (
        <div>
          <h1>Responsive Table</h1>
          <div id="wrapper">
            <table>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>School</th>
                  <th>Major</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-label="First Name">Bob</td>
                  <td data-label="Last Name">Smith</td>
                  <td data-label="School">Northeastern University</td>
                  <td data-label="Major">
                    <button className="issue-button">issue</button>{' '}
                    <button className="issue-button">reject</button>
                  </td>
                </tr>
                <tr>
                  <td data-label="First Name">James</td>
                  <td data-label="Last Name">Johnson</td>
                  <td data-label="School">Northeastern University</td>
                  <td data-label="Major">Digital Media</td>
                </tr>
                <tr>
                  <td data-label="First Name">Mike</td>
                  <td data-label="Last Name">Williams</td>
                  <td data-label="School">Northeastern University</td>
                  <td data-label="Major">Leadership</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      );
}

export default RequstedCouponDistrict2