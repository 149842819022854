import React, { useState, useEffect } from 'react';
import { BaseUrl } from '../Constants/BaseUrl';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function MasterTable() {
  const [MasterData, setMasterData] = useState([]);
  const [UserTypeData, setUserTypeData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
const [IsFinished, setIsFinished] = useState(false)
const RefreshData=()=>{
  setIsFinished(!IsFinished)
}
  const handleDropdownChange = (event, dealerId) => {
    const confirmMessage = "Are you sure you want change?";
    if (window.confirm(confirmMessage)) {
      const selectedOption = event.target.value;
      axios.post(`${BaseUrl}/UpdateDealerStatus?UserTypeId=${selectedOption}&DealerId=${dealerId}`)
        .then((response) => {
          console.log(response.data);
          RefreshData()
          toast.success('Account type changed successfully!', {
            position: 'top-center' // Set the position to top-center
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchQuery(searchValue);

    const filteredData = MasterData.filter((item) => {
      for (const key in item) {
        const value = item[key];
        if (typeof value === 'string' && value.toLowerCase().includes(searchValue)) {
          return true;
        } else if (typeof value === 'number' && value.toString().includes(searchValue)) {
          return true;
        }
      }
      return false;
    });
    setFilteredData(filteredData);
  };

  useEffect(() => {
    axios.get(`${BaseUrl}/getDealers`)
      .then((response) => {
        // console.log(response.data)
        setMasterData(response.data);
        setFilteredData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios.get(`${BaseUrl}/getusertype`)
      .then((response) => {
        // console.log(response.data)
        setUserTypeData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [IsFinished]);

  const dataToDisplay = searchQuery.length > 0 ? filteredData : MasterData;
//Account Delete
function DeleteAccount(dealerid) {
  // console.log(dealerid)
  const confirmMessage = "Are you sure you want to deactivate this account?";

  if (window.confirm(confirmMessage)) {
    axios.post(BaseUrl + `/DeleteDealerAccount?DealerId=${dealerid}`)
      .then(response => {
        RefreshData()
          // console.log(response.data)
          toast.success('Account deactivated successfully!', {
            position: 'top-center' // Set the position to top-center
          });
      })
      .catch(error => {
        console.log(error);
      });
  } else {
    console.log("Account deletion cancelled");
  }
}
function ActivateAccount(dealerid) {
  // console.log(dealerid)
  const confirmMessage = "Are you sure you want to activate this account?";

  if (window.confirm(confirmMessage)) {
    axios.post(BaseUrl + `/ActivateDealerAccount?DealerId=${dealerid}`)
      .then(response => {
        RefreshData()
        toast.success('Account activated successfully!', {
          position: 'top-center' // Set the position to top-center
        });
          // console.log(response.data)
      })
      .catch(error => {
        console.log(error);
      });
  } else {
    console.log("Account Activation cancelled");
  }
}
  return (
    <>
      <div className='col-lg-5 col-6' style={{ padding: '10px' }}>
        <input
          type='text'
          className='form-control'
          style={{ width: '100%' }}
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearch}
        />
      </div>
      <div className='row my-table-container'>
    <div className="col-12 ">
      <table className="table table-bordered table-responsive">
        <thead style={{ background: '#064185', color: 'White' }}>
          <tr>
          <th className="text-center">Slno</th>
          <th className="text-center">Member Code</th>
            <th className="text-center">Owner Name</th>
            <th className="text-center">Shop Name</th>
            <th className="text-center">District</th>
            <th className="text-center">Phone Number</th>
            <th className="text-center">Account Type</th>
            <th className="text-center">Status</th>
          </tr>
        </thead>
        <tbody>
          {dataToDisplay.map((data,index) => (
            <tr className="text-center" key={index}>
              <td data-label="Slno">{index+1}</td>
              <td data-label="Member Code">{data.DealerCode}</td>
              <td data-label="Owner Name">{data.Name}</td>
              <td data-label="Shop Name">{data.ShopName}</td>
              <td data-label="District">{data.DistrictName}</td>
              <td data-label="Phone Number">{data.MobileNo}</td>
              <td data-label="User Type">
                <select
                  style={{ height: '30px',width:'100%' }}
                  value={data.UserTypeId}
                  onChange={(e) => handleDropdownChange(e, data.Id)}
                  className='MasterDataSelect'
                >
                  {UserTypeData.map((type) => (
                    <option key={type.Id} value={type.Id}>
                      {type.Name}
                    </option>
                  ))}
                </select>
              </td>
              <td data-label="Delete">
  {data.Status === 0 ? (
    <button
      style={{
        width: '100%',
        fontSize: '11px',
        background: 'red',
        color: '#fff',
        height: '30px',
      }}
      onClick={() => DeleteAccount(data.Id)}
    >
      Deactivate
    </button>
  ) : (
    <button
      style={{
        width: '100%',
        fontSize: '11px',
        background: 'rgb(62, 148, 140)',
        color: '#fff',
        height: '30px',
      }}
      onClick={()=>ActivateAccount(data.Id)}
    >
      Activate
    </button>
  )}
</td>

            </tr>
          ))}
        </tbody>
      </table>
      </div>
      </div>
    </>
  );
}

export default MasterTable;
