import logo from './logo.svg';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DistrictCouponPage from './Pages/DistrictCouponPage';
import Login from './Pages/Login'
import StateCommittePage from './Pages/StateCommittePage';
import DealerCouponPage from './Pages/DealerCouponPage'
import DealerCouponPage2 from './Pages/DealerCouponPage2';
import NewLogin from './Pages/NewLogin';
import DealerPageWithSideMenu from './Pages/DealerPageWithSideMenu';
import StatePageWithSideMenu from './Pages/StatePageWithSideMenu';
import DistrictPageWithSideMenu from './Pages/DistrictPageWithSideMenu';
import PrivacyPolicy from './Components/PrivacyPolicy';
import PrivacyPolicyPage from './Pages/PrivacyPolicyPage';
function App() {
  return (
    <div className="App">
  <Router>
        <Routes>
          {/* <Route path="/" element={<LoginRegistration />} /> */}
          <Route path="/" element={<NewLogin/>} /> 
          <Route path="/district" element={<DistrictPageWithSideMenu />} />
          <Route path="/statecommitte" element={<StatePageWithSideMenu/>} />
          <Route path="/member" element={<DealerPageWithSideMenu/>} />
          <Route path="/sidemenu" element={<DealerCouponPage2/>} />
          <Route path="/sidemenuState" element={<StateCommittePage/>} />
          <Route path="/districtside" element={<DistrictCouponPage/>} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
