import React, { useState } from 'react';
import '../CSS/CouponSelector.css'; // Import the CSS file for the component
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { BaseUrl } from '../Constants/BaseUrl';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const CouponSelector = (props) => {
  const user = JSON.parse(localStorage.getItem('user'))
  const [selectedCoupons, setSelectedCoupons] = useState([]);
  const [inputNumber, setInputNumber] = useState('');
  // console.log(props.DistrictDetails)
  const handleCouponSelection = (input) => {
    const sanitizedInput = String(input).replace(/\s/g, '');
  
    if (/^\d+$/.test(sanitizedInput)) {
      const couponNumber = parseInt(sanitizedInput);
      if (
        selectedCoupons.includes(couponNumber) ||
        !props.CouponData.some((coupon) => coupon.Number === couponNumber)
      ) {
        setSelectedCoupons(selectedCoupons.filter((coupon) => coupon !== couponNumber));
      } else if (selectedCoupons.length < props.DistrictDetails.Number) {
        setSelectedCoupons([...selectedCoupons, couponNumber]);
      }
    } else if (/^\d+(,\d+)*$/.test(sanitizedInput)) {
      const couponNumbers = sanitizedInput.split(',').map((number) => parseInt(number));
      const validCoupons = couponNumbers.filter(
        (number) =>
          !selectedCoupons.includes(number) &&
          props.CouponData.some((coupon) => coupon.Number === number)
      );
      const remainingSlots = props.DistrictDetails.Number - selectedCoupons.length;
      const couponsToAdd = validCoupons.slice(0, remainingSlots);
      setSelectedCoupons([...selectedCoupons, ...couponsToAdd]);
    } else if (/^\d+-\d+$/.test(sanitizedInput)) {
      const [start, end] = sanitizedInput.split('-').map((number) => parseInt(number));
      if (start <= end) {
        const range = Array.from({ length: end - start + 1 }, (_, index) => start + index);
        const validCoupons = range.filter(
          (number) =>
            !selectedCoupons.includes(number) &&
            props.CouponData.some((coupon) => coupon.Number === number)
        );
        const remainingSlots = props.DistrictDetails.Number - selectedCoupons.length;
        const couponsToAdd = validCoupons.slice(0, remainingSlots);
        setSelectedCoupons([...selectedCoupons, ...couponsToAdd]);
      }
    }
  };
  const [BookIssueButton, setBookIssueButton] = useState(false)
  function IssueCoupon(){
    const dealerid=user.id
    setBookIssueButton(true)
    const formattedCoupons = selectedCoupons.map((couponNumber) => ({
        districtid: props.DistrictDetails.Id,
        number: couponNumber
        ,did:dealerid
      }));  
      
      // console.log(formattedCoupons)
    axios.put(BaseUrl+`/UpdateCoupon`,formattedCoupons)
    .then(response => {
      // console.log(formattedCoupons)
       const NewNumber=props.DistrictDetails.Number-selectedCoupons.length
       const DistrictData={districtid:props.DistrictDetails.Id,number:NewNumber}
      //  console.log(DistrictData)
        axios.put(BaseUrl+`/updatedrequest`,DistrictData)
        .then(response => {
            if(response.data){
              console.log('updated')
              toast.success('Coupon issued successfully!', {
                position: 'top-center' // Set the position to top-center
              });
            }
        })
        .then(()=> {
            props.toggleIsFinished()
            setSelectedCoupons([]);
            props.onClose()
        });
    })
    .catch(error=> {
        console.log(error);
    }).finally(() => {
      // Enable the button again after the request is completed
      setBookIssueButton(false)
    });
  }
  return (
    <Modal show={props.showModal} onHide={() => {
      setSelectedCoupons([])
      props.onClose();
    }}>
    <Modal.Header closeButton>
      <Modal.Title>
      <h4>Total books in hand: {props.CouponData.length}</h4>
      <h6>District:{props.DistrictDetails.Name}</h6>
      <input
            type="text"
            value={inputNumber}
            onChange={(e) => setInputNumber(e.target.value)}
            placeholder="Enter numbers or range"
            style={{width:'70%', fontSize: '18px'}}
          />
           <button type="submit" className='issue-button' onClick={() => handleCouponSelection(inputNumber)} style={{marginLeft:'10px'}}>Select</button>
       </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="modalbody">
        <div className='container'>
        <div className="coupon-selector">
          <div className="coupon-list">
          {props.CouponData.map((coupon) => (
  <button
    key={coupon.Number}
    className={selectedCoupons.includes(coupon.Number) ? 'selected' : ''}
    onClick={() => handleCouponSelection(coupon.Number)}
    style={{ position: 'relative' }}
  >
    <div style={{ textAlign: 'center' }}>
      {coupon.Number}
    </div>
    <div
      style={{
        fontSize: '8px',
        color:'rgba(0, 0, 0, 0.8)',
        position: 'absolute',
        bottom: '5px',
        left: '5px',
        right: '5px',
        textAlign: 'center',
      }}
    >
      ({coupon.Number * 100 - 99}-{coupon.Number * 100})
    </div>
  </button>
))}

          </div>
        </div>
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer>
  <div className="row">
    <div className="col-sm-12 col-md-6 d-flex align-items-center justify-content-start">
      <h5>Selected Coupons: {selectedCoupons.length}</h5>
    </div>
    <div className="col-sm-12 col-md-6">
      <button className="issue-button" onClick={IssueCoupon} disabled={BookIssueButton}>{BookIssueButton? 'wait..' : 'Issue'}</button>
    </div>
  </div>
</Modal.Footer>
  </Modal>
  );
};

export default CouponSelector;