import React from 'react'

function PrivacyPolicyPage() {
  return (
    <div>
    <h2>SECTION 1 - WHAT DO WE DO WITH YOUR INFORMATION?</h2>
    <p>
      When you book something from our App, as part of the booking process, we collect the personal information you give us such as your name, phone number, address, and email address. We use your Personal Information for providing and improving the App.
    </p>

    <h2>SECTION 2 - CONSENT</h2>
    <p>
      How do you get my consent? When you provide us with personal information to complete a booking, we imply that you consent to our collecting it and using it for that specific reason only.
    </p>

    <h2>SECTION 3 - DISCLOSURE</h2>
    <p>
      We may disclose your personal information if we are required by law to do so or if you violate our Terms of Service.
    </p>

    <h2>SECTION 4 - THIRD-PARTY SERVICES</h2>
    <p>
      In general, the third-party providers used by us will only collect, use and disclose your information to the extent necessary to allow them to perform the services they provide to us. However, certain third-party service providers, such as payment gateways and other payment transaction processors, have their own privacy policies in respect to the information we are required to provide to them for your purchase-related transactions. For these providers, we recommend that you read their privacy policies so you can understand the way your personal information will be handled by these providers. In particular, remember that certain providers may be located in or have facilities that are located in a different jurisdiction than either you or us. So, if you elect to proceed with a transaction that involves the services of a third-party service provider, then your information may become subject to the laws of the jurisdiction(s) in which that service provider or its facilities are located. Once you leave our store’s website or are redirected to a third-party website or application, you are no longer governed by this Privacy Policy or our website’s Terms of Service.
    </p>
    <p>
      <strong>Links:</strong> When you click on links on our store, they may direct you away from our site. We are not responsible for the privacy practices of other sites and encourage you to read their privacy statements.
    </p>

    <h2>SECTION 5 - SECURITY</h2>
    <p>
      To protect your personal information, we take reasonable precautions and follow industry best practices to make sure it is not inappropriately lost, misused, accessed, disclosed, altered, or destroyed.
    </p>

    <h2>SECTION 6 - COOKIES</h2>
    <p>
      We use cookies to maintain the session of your user. It is not used to personally identify you on other websites.
    </p>

    <h2>SECTION 7 - AGE OF CONSENT</h2>
    <p>
      By using this site, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence, and you have given us your consent to allow any of your minor dependents to use this site.
    </p>

    <h2>SECTION 8 - CHANGES TO THIS PRIVACY POLICY</h2>
    <p>
      We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website. If we make material changes to this policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it.
    </p>

    <h2>QUESTIONS AND CONTACT INFORMATION</h2>
    <p>
      If you have any queries please contact us by mail at <a href="mailto:tdaakkerala2019@gmail.com">tdaakkerala2019@gmail.com</a>.
    </p>
    <p>
      TYRE DEALERS AND ALIGNMENT ASSOCIATION, KERALA<br />
      10/4, Kalathil Building, Nettoor P.O., Ernakulam - 682040
    </p>

    <p>
      The terms "We" / "Us" / "Our"/” Company” individually and collectively refer to Tyre Dealers & Alignment Association, Kerala, and the terms "You" /"Your" / "Yourself" refer to the users.
    </p>

    <p>
      This Privacy Policy is an electronic record in the form of an electronic contract formed under the Information Technology Act, 2000 and the rules made thereunder and the amended provisions pertaining to electronic documents/records in various statutes as amended by the Information Technology Act, 2000. This Privacy Policy does not require any physical, electronic, or digital signature.
    </p>

    <p>
      This Privacy Policy is a legally binding document between you and Tyre Dealers & Alignment Association, Kerala (both terms defined below). The terms of this Privacy Policy will be effective upon your acceptance of the same (directly or indirectly in electronic form, by clicking on the I accept tab or by use of the website or by other means) and will govern the relationship between you and to Tyre Dealers & Alignment Association, Kerala for your use of the website “Website” (defined below).
    </p>

    <p>
      This document is published and shall be construed in accordance with the provisions of the Information Technology (reasonable security practices and procedures and sensitive personal data or information) rules, 2011 under Information Technology Act, 2000; which require publishing of the Privacy Policy for collection, use, storage, and transfer of sensitive personal data or information.
    </p>

    <p>
      Please read this Privacy Policy carefully by using the Website, you indicate that you understand, agree and consent to this Privacy Policy. If you do not agree with the terms of this Privacy Policy, please do not use this Website.
    </p>

    <p>
      By providing us your Information or by making use of the facilities provided by the Website, you hereby consent to the collection, storage, processing, and transfer of any or all Your Personal Information and Non-Personal Information by us as specified under this Privacy Policy. You further agree that such collection, use, storage, and transfer of Your Information shall not cause any loss or wrongful gain to you or any other person.
    </p>

    <h2>USER INFORMATION</h2>
    <p>
      To avail of certain services on our websites, users are required to provide certain information for the registration process namely: - a) your name, b) email address, c) phone d) password, etc. The Information as supplied by the users enables us to improve our sites and provide you with the most user-friendly experience.
    </p>

    <p>
      All required information is service dependent, and we may use the above said user information to, maintain, protect, and improve its services (including advertising services) and for developing new services.
    </p>

    <p>
      Such information will not be considered as sensitive if it is freely available and accessible in the public domain or is furnished under the Right to Information Act, 2005, or any other law for the time being in force.
    </p>

    <h2>COOKIES</h2>
    <p>
      To improve the responsiveness of the sites for our users, we may use "cookies", or similar electronic tools to collect information to assign each visitor a unique, random number as a User Identification (User ID) to understand the user's individual interests using the Identified Computer. Unless you voluntarily identify yourself (through registration, for example), we will have no way of knowing who you are, even if we assign a cookie to your computer. A cookie cannot read data off your hard drive. Our advertisers may also assign their own cookies to your browser (if you click on their ads), a process that we do not control.
    </p>

    <p>
      Our web servers automatically collect limited information about your computer's connection to the Internet, including your IP address, when you visit our site. (Your IP address is a number that lets computers attached to the Internet know where to send you data -- such as the web pages you view.) Your IP address does not identify you personally. We use this information to deliver our web pages to you upon request, to tailor our site to the interests of our users, to measure traffic within our site, and let advertisers know the geographic locations from where our visitors come.
    </p>

    <h2>LINKS TO THE OTHER SITES</h2>
    <p>
      Our policy discloses the privacy practices for our own website only. Our site provides links to other websites also that are beyond our control. We shall in no way be responsible in way for your use of such sites.
    </p>

    <h2>INFORMATION SHARING</h2>
    <p>
      We share sensitive personal information with any third party without obtaining the prior consent of the user in the following limited circumstances:
    </p>

    <ol>
      <li>
        <p>When it is requested or required by law or by any court or governmental agency or authority to disclose, for the purpose of verification of identity, or for the prevention, detection, investigation including cyber incidents, or for prosecution and punishment of offenses. These disclosures are made in good faith and the belief that such disclosure is reasonably necessary for enforcing these Terms; for complying with the applicable laws and regulations.</p>
      </li>
      <li>
        <p>We propose to share such information within its group companies and officers and employees of such group companies for the purpose of processing personal information on its behalf. We also ensure that these recipients of such information agree to process such information based on our instructions and in compliance with this Privacy Policy and any other appropriate confidentiality and security measures.</p>
      </li>
    </ol>

    <h2>INFORMATION SECURITY</h2>
    <p>
      We take appropriate security measures to protect against unauthorized access to or unauthorized alteration, disclosure, or destruction of data. These include internal reviews of our data collection, storage, and processing practices, and security measures, including appropriate encryption and physical security measures to guard against unauthorized access to systems where we store personal data.
    </p>

    <p>
      All information gathered on our website is securely stored within our controlled database. The database is stored on servers secured behind a firewall; access to the servers is password-protected and is strictly limited. However, as effective as our security measures are, no security system is impenetrable. We cannot guarantee the security of our database, nor can we guarantee that the information you supply will not be intercepted while being transmitted to us over the Internet. And, of course, any information you include in a posting to the discussion areas is available to anyone with Internet access.
    </p>

    <p>
      However, the internet is an ever-evolving medium. We may change our Privacy Policy from time to time to incorporate necessary future changes. Of course, our use of any information we gather will always be consistent with the policy under which the information was collected, regardless of what the new policy may be.
    </p>

    <h2>Grievance Redressal</h2>
    <p>
      Redressal Mechanism: Any complaints, abuse, or concerns with regards to content and or comment or breach of these terms shall be immediately informed to the designated Grievance Officer as mentioned below in writing or through email signed with the electronic signature to ("Grievance Officer").
    </p>

    <p>
      ASSY THOMAS (President/ Grievance Officer)<br />
      <a href="https://tdaak.org">https://tdaak.org</a><br />
      TYRE DEALERS AND ALIGNMENT ASSOCIATION, KERALA<br />
      10/4, Kalathil Building, Nettoor P.O., Ernakulam - 682040<br />
      Email: <a href="mailto:tdaakkerala2019@gmail.com">tdaakkerala2019@gmail.com</a><br />
      Phone: 9447208837
    </p>
  </div>
  )
}

export default PrivacyPolicyPage