import React,{useState} from 'react'
import { Modal, Button } from 'react-bootstrap';
function RejectReasonModal(props) {
    const RejectData=props.RejectData
    const [FeedbackDesc, setFeedbackDesc] = useState('')
    function Reject(){
        props.closeFeedbackModal()
      props.RejectPayment(RejectData.Id,RejectData.Amount,RejectData.dealerId,FeedbackDesc)
    }
  return (
    <Modal show={props.feedbackModal} onHide={props.closeFeedbackModal} id="writefeedback" tabIndex="-1" centered>
    <Modal.Header closeButton>
      <Modal.Title><h5>Write your Reason</h5></Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="row">
        <div className="col-lg-12">
          <div className="input-item">
            <label>Write your reason Here</label>
            <textarea className="form-control" cols="30" rows="10" placeholder="Share the reason of rejection." onChange={(e)=>setFeedbackDesc(e.target.value)} ></textarea>
          </div>
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <button type="button" className="addnew-btn" onClick={Reject}>Submit</button>
    </Modal.Footer>
  </Modal>
  )
}

export default RejectReasonModal