import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, Label } from 'recharts';
import axios from 'axios';
import { BaseUrl } from '../Constants/BaseUrl';

function TargetPie() {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get(BaseUrl + `/onekcoupon?num=${'1000'}`)
      .then((res) => {
        setData(res.data);
        // console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Define an array of colors for the regions
  const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  // Calculate the angle for label positioning
  const calculateLabelAngle = (startAngle, endAngle) => {
    const midAngle = (startAngle + endAngle) / 2;
    return midAngle > 90 && midAngle < 270 ? midAngle + 180 : midAngle;
  };

  // Calculate the total number of books
  const totalBooks = data.reduce((total, entry) => total + entry.books, 0);

  // Calculate the percentage for each entry
  const dataWithPercentage = data.map((entry) => ({
    ...entry,
    percentage: ((entry.books / totalBooks) * 100),
  }));

  return (
    <div className="row">
    <div   >
    
    </div>
    <div>
    
      <div className='col-7' >
      <h5 >TDAAC-Dealer Performance Report</h5>
        <PieChart width={400} height={400}>
          <Pie
            data={dataWithPercentage}
            dataKey="books"
            outerRadius={150}
            label
            startAngle={0}
            endAngle={360}
            animationBegin={200}
            animationDuration={1000}
          >
            {dataWithPercentage.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
            ))}
            {/*dataWithPercentage.map((entry, index) => (
              <Label
                key={`label-${index}`}
                value={entry.name}
                position={calculateLabelAngle(entry.startAngle, entry.endAngle)}
                style={{ fill: '#fff', fontSize: '14px' }}
              />
            ))*/}
          </Pie>
        </PieChart>
      </div>
        </div>
    <div className='col-6'  >
      <div>
        <div >
          <table className="table">
            <thead>
              <tr>
                <th>Dealer</th>
                <th>Books</th>
                <th>Percentage</th>
              </tr>
            </thead>
            <tbody>
              {dataWithPercentage.map((entry, index) => (
                <tr key={`row-${index}`} style={{ backgroundColor: colors[index % colors.length] }}>
                  <td>{entry.name}</td>
                  <td>{entry.books}</td>
                  <td>{entry.percentage.toFixed(2)}%</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>  );
}

export default TargetPie;