import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { BaseUrl } from '../Constants/BaseUrl';
import Select from 'react-select';
function MemberPaymenReportDistrict(props) {
  const [PaymentHistoryData, setPaymentHistoryData] = useState([]);
  const [DealerId, setDealerId] = useState('default');
  const [CouponInHand, setCouponInHand] = useState(0)
  const [MemberName, setMemberName] = useState('-')
  const [MemberCode, setMemberCode] = useState('-')
  const [RejectedData, setRejectedData] = useState([])

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    return `${formattedDay}/${formattedMonth}/${year}`;
  }
  const styles={
    rowStyles: {
      '--bs-gutter-x': 'initial' // Set the property to 'initial' to remove it
    }
  }

  useEffect(() => {
    // console.log(props.DealersData)
    if (DealerId !== 'default') {
           //payment history
           axios.get(`${BaseUrl}/getpaymentaccounts?dealerid=${DealerId}`)
           .then((res) => {
          console.log(res.data)
          setPaymentHistoryData(res.data)
           })
           .catch((error) => console.log(error));
           axios.get(`${BaseUrl}/getdealercoupondash?did=${DealerId}`)
           .then((res) => {
             if (res.data) {
              //  console.log(res.data);
               calculateRowCountSum(res.data)
             }
           })
           .catch((error) => console.log(error));
           axios.get(`${BaseUrl}/getpaymentmemberwise?memberid=${DealerId}`)
      .then((res) => {
          // setPaymentHistoryData(res.data)
          const filteredData = res.data.filter((item) => item.Status === 1)
          console.log(res.data)
          setRejectedData(filteredData)
      })
      .catch((error) => console.log(error));
    }
  }, [DealerId]);
  const options = [
    { value: 'default', label: 'Select a Member', isDisabled: true },
    ...props.DealersData.map((data) => ({ value: data.Id, label: `${data.DealerNo} - ${data.Shopename}`,Name:data.Name,Code:data.DealerNo })),
  ];
//payment total
  // Calculate the total amount
const totalAmount = PaymentHistoryData.reduce((total, data) => {
  return data.Status === 0 ? total - data.amount : total + data.amount;
}, 0);
const result = totalAmount > 0 ? 0 : Math.abs(totalAmount);

// Determine the color for "Amount to be paid"
const amountColor = totalAmount < 0 ? 'red' : 'green';

function calculateRowCountSum(array) {
  let sum = 0;
  for (let i = 0; i < array.length; i++) {
    if (typeof array[i].RowCount === 'number') {
      sum += array[i].RowCount;
    }
  }
  setCouponInHand(sum)
}
//
const [IsRejectedPayment, setIsRejectedPayment] = useState(false)
const [IsPaymentLedger, setIsPaymentLedger] = useState(true)
const ChangeTable =(x)=>{
  if(x===1){
    setIsPaymentLedger(true)
    setIsRejectedPayment(false)
  }
  else if(x===2){
    setIsPaymentLedger(false)
    setIsRejectedPayment(true)
  }
}
  return (
    <>
      <div className='row mb-4'>
        <div className='col-lg-5 col-6'>
          {/* <select
            value={DealerId}
            onChange={(e) => setDealerId(e.target.value)}
            style={{ width: '250px', height: '40px', textAlign: 'center', borderRadius: '20px' }}
          >
            <option value='default' disabled>
              Select a Member
            </option>
            {props.DealersData.map((data) => (
              <option key={data.Id} value={data.Id}>{`${data.DealerNo} - ${data.Shopename}`}</option>
            ))}
          </select> */}
          <Select
      value={options.find((option) => option.value === DealerId)}
      onChange={(selectedOption) =>{ setDealerId(selectedOption.value);setMemberName(selectedOption.Name);setMemberCode(selectedOption.Code)}}
      options={options}
      styles={{
        control: (provided) => ({
          ...provided,
          width: '250px',
          height: '40px',
          textAlign: 'center',
          borderRadius: '20px',
          margin: '0 auto',
        }),
      }}
    />
        </div>
      </div>
      <div className="row" style={styles.rowStyles}>
      <div className="col-4 mb-4">
    <div className="card">
      <span className="big">{MemberCode}</span>
      <span className="small">{MemberName}</span>
    </div>
  </div>
  <div className="col-4 mb-4">
    <div className="card">
      <span className="big">{CouponInHand}</span>
      <span className="small">Book in Hand</span>
    </div>
  </div>
  <div className="col-4 mb-4">
    <div className="card">
      <span className="big" style={{color:amountColor}}>₹{result}</span>
      <span className="small">Payment pending</span>
    </div>
  </div>
       </div>
      {IsPaymentLedger&&
      <div className='col-lg-12'>
        <div className='text-center'>
          <h3 style={{ padding: '10px' }}>Member Payment Ledger</h3>
        </div>
        <label style={{color: 'red', cursor: 'pointer', textDecoration: 'underline'}} onClick={()=>ChangeTable(2)}>Rejected payments</label>
        <table className="table table-bordered table-responsive">
  <thead style={{ background: '#064185', color: 'White' }}>
    <tr>
      <th className="text-center">Date</th>
      <th className="text-center">Particulars</th>
      <th className="text-center">Amount</th>
    </tr>
  </thead>
  <tbody>
    {PaymentHistoryData && PaymentHistoryData.length > 0 ? (
      PaymentHistoryData.map((data, index) => (
        <tr key={index}>
          <td className="text-center">{formatDate(data.Date)}</td>
          <td className="text-center">{data.Particulars}</td>
          <td className="text-center" style={{ color: data.Status === 0 ? 'red' : 'green' }}>
            {data.Status === 0 ? '-' : '+'}₹{data.amount}
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan="3" className="text-center">
          No Payment Data
        </td>
      </tr>
    )}
    <tr>
      <td className="text-center"></td>
      {totalAmount <= 0 ? (
        <td className="text-center">
          <strong>Amount to be paid</strong>
        </td>
      ) : (
        <td className="text-center">
          <strong>extra amount paid</strong>
        </td>
      )}
      <td className="text-center" style={{ color: amountColor }}>
        <strong>₹{Math.abs(totalAmount)}</strong>
      </td>
    </tr>
  </tbody>
</table>

      </div>}
      {IsRejectedPayment&&<div className='col-lg-12 my-table-container'>
        <div className='text-center'>
          <h3 style={{ padding: '10px' }}>Rejected Payments</h3>
        </div>
        <label style={{color: 'red', cursor: 'pointer', textDecoration: 'underline'}} onClick={()=>ChangeTable(1)}>Member Payment Ledger</label>
        <table className="table table-bordered table-responsive">
      <thead style={{ background: '#064185', color: 'White' }}>
        <tr>
          <th className="text-center">Date</th>
          <th className="text-center">Amount</th>
          <th className="text-center">Reason</th>
        </tr>
      </thead>
      <tbody>
        {RejectedData.length === 0 ? (
          <tr>
            <td colSpan="3" className="text-center">
              No data available
            </td>
          </tr>
        ) : (
          RejectedData.map((data, index) => (
            <tr key={index}>
              <td data-label="Date">{formatDate(data.PaymentDate)}</td>
              <td data-label="Amount" style={{ fontWeight: '700' }}>
                {data.Amount}
              </td>
              <td
           data-label="Remarks"
           style={{
          color: 'red',
          fontWeight: '700',
          }}
         >
    {data.Remarks === null || data.Remarks === undefined || data.Remarks.length === 0
            ? '-'
            : data.Remarks}
             </td>

            </tr>
          ))
        )}
      </tbody>
    </table>
      </div>}
    </>
  );
}

export default MemberPaymenReportDistrict;
