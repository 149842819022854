import React,{useState,useEffect} from 'react'
import RequstedCouponDistrict from '../Components/RequstedCouponDistrict';
import '../CSS/DealerPageWithSideMenu.css'
import { Nav,Tab } from 'react-bootstrap';
import Logo from '../Images/LoginImages/tdaak-logo.png'
import ReportPng from '../Images/img/report.png'
import CouponsPng from '../Images/img/icons8-coupons-58.png'
import CouponIssue from '../Images/img/coupon.png'
import {  useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BaseUrl } from '../Constants/BaseUrl';
import axios from 'axios';
import WalletPng from '../Images/img/wallet.png'
import GiftVoucherPng from '../Images/img/gift-voucher.png'
import cashPaymentPng from '../Images/img/pay.png'
import PaymentRequestDistrict from '../Components/PaymentRequestDistrict';
import PaymentPng from '../Images/img/icons8-payment-50.png'
import MemberPaymenReportDistrict from '../Components/MemberPaymenReportDistrict';
import DistrictPaymentHistory from '../Components/DistrictPaymentHistory';
import Select from 'react-select';
function DistrictPageWithSideMenu() {
  useEffect(() => {
    const userString = localStorage.getItem('user');
    const user = JSON.parse(userString); // Parse the string to an object
    console.log(user.usertype);
  
    if (!user || user.usertype !== 'F4688F9E-702F-4147-A44A-99866FE9124A') {
      alert('Access Denied: You are not authorized as a District Committee Member.');
      navigate('/');
    }
  }, []);
  //Refresh
  const [startY, setStartY] = useState(0);
  const [refreshThreshold, setRefreshThreshold] = useState(150);
  const [isRefreshing, setIsRefreshing] = useState(false);
  useEffect(() => {
    const handleTouchStart = (event) => {
      setStartY(event.touches[0].clientY);
    };

    const handleTouchEnd = (event) => {
      const deltaY = event.changedTouches[0].clientY - startY;
      if (deltaY > refreshThreshold) {
        setIsRefreshing(true);
        window.location.reload();
      }
    };

    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchend', handleTouchEnd);

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [refreshThreshold, startY]);

  /////////////
  const navigate=useNavigate()
  const [activeTab, setActiveTab] = useState('A');
  const handleTabSelect = tab => {
    setActiveTab(tab);
  };
  const DistrictId = JSON.parse(localStorage.getItem('DistrictId'))
  const user = JSON.parse(localStorage.getItem('user'))
  const dealerid=user.id
  const [DistrictName, setDistrictName] = useState('')
  const [DistrictCouponData, setDistrictCouponData] = useState([])
  const [DealersData, setDealersData] = useState([])
  const [DealerId, setDealerId] = useState("default")
  const [PaymentDealerId, setPaymentDealerId] = useState("default")
  const [PaymentAmount, setPaymentAmount] = useState('')
  const [PaymentDate, setPaymentDate] = useState(new Date().toISOString().split('T')[0])
  const [CouponNumber, setCouponNumber] = useState('')
  const [selectedCoupons, setSelectedCoupons] = useState([]);
  const [IsFinished, setIsFinished] = useState(true)
  const [PaymentRequestData, setPaymentRequestData] = useState([])
const [PaymentRequestFinished, setPaymentRequestFinished] = useState(false)
  const toggleIsFinishedPayment = () => {
    setPaymentRequestFinished(!PaymentRequestFinished);
  };
  const toggleIsFinished = () => {
    setIsFinished(!IsFinished);
  };
  //Get Coupon Request 
  useEffect(() => {
    axios.get(BaseUrl+`/couponsinhand?Id=${DistrictId}`)
    .then(response => {
      // console.log(response.data)
     setDistrictCouponData(response.data)
    })
    .catch(error => {
      console.log(error);
    });
  }, [IsFinished])

  useEffect(() => {
    axios.get(BaseUrl+`/getpayrequests?districtid=${DistrictId}&paymode=1&status=0`)
    .then(response => {
      // console.log(response.data)
   setPaymentRequestData(response.data)
    })
    .catch(error => {
      console.log(error);
    });
  }, [PaymentRequestFinished])
 const [ReqBookButton, setReqBookButton] = useState(false)
  function Request(){
    const RequestCouponData={number:CouponNumber,districtid:DistrictId,did:dealerid}
    // console.log(RequestCouponData)
    if ( CouponNumber<= 0 || !Number.isInteger(CouponNumber)) {
      alert('Please enter a valid number of books')
    }
   else{
    setReqBookButton(true)
    axios.post(BaseUrl+`/setdrequest`,RequestCouponData)
    .then(response => {
      toast.success('Request successful!', {
        position: 'top-center' // Set the position to top-center
      });
    setCouponNumber('')
    })
    .catch(error=> {
        console.log(error);
    }).finally(() => {
      setReqBookButton(false);
    });
   }
  }
  //Coupon issue without request
  const [inputNumber, setInputNumber] = useState('')
  const handleCouponSelection = (input) => {
    const sanitizedInput = String(input).replace(/\s/g, '');
  
    if (/^\d+$/.test(sanitizedInput)) {
      const couponNumber = parseInt(sanitizedInput);
      if (
        selectedCoupons.includes(couponNumber) ||
        !DistrictCouponData.some((coupon) => coupon.Number === couponNumber)
      ) {
        setSelectedCoupons(selectedCoupons.filter((coupon) => coupon !== couponNumber));
      } else {
        setSelectedCoupons([...selectedCoupons, couponNumber]);
      }
    } else if (/^\d+(,\d+)*$/.test(sanitizedInput)) {
      const couponNumbers = sanitizedInput.split(',').map((number) => parseInt(number));
      const validCoupons = couponNumbers.filter(
        (number) =>
          !selectedCoupons.includes(number) &&
          DistrictCouponData.some((coupon) => coupon.Number === number)
      );
      setSelectedCoupons([...selectedCoupons, ...validCoupons]);
    } else if (/^\d+-\d+$/.test(sanitizedInput)) {
      const [start, end] = sanitizedInput.split('-').map((number) => parseInt(number));
      if (start <= end) {
        const range = Array.from({ length: end - start + 1 }, (_, index) => start + index);
        const validCoupons = range.filter(
          (number) =>
            !selectedCoupons.includes(number) &&
            DistrictCouponData.some((coupon) => coupon.Number === number)
        );
        setSelectedCoupons([...selectedCoupons, ...validCoupons]);
      }
    }
  };
  
  useEffect(() => {
    axios.get(BaseUrl+`/couponsinhand?Id=${DistrictId}`)
    .then(response => {
      // console.log(response.data)
     setDistrictCouponData(response.data)
    })
    .catch(error => {
      console.log(error);
    });
  }, [IsFinished])
  useEffect(() => {
    axios.get(BaseUrl+`/getdistrictwisedealers?Id=${DistrictId}`)
    .then(response => {
      // console.log(response.data)
     setDealersData(response.data)
    })
    .catch(error => {
      console.log(error);
    });
    axios.get(BaseUrl+`/GetIndividualDistrict?Id=${DistrictId}`)
    .then(response => {
      setDistrictName(response.data[0].Name)
    })
    .catch(error => {
      console.log(error);
    });
  }, [])

  const [IssueCouponButton, setIssueCouponButton] = useState(false)
  function IssueCoupon(){
    const formattedCoupons = selectedCoupons.map((couponNumber) => ({
      dealerid: DealerId,
      number: couponNumber,
      did:dealerid 
    }));  
    console.log(formattedCoupons)
    if(DealerId==='default'){
      alert('please select a member')
    }
    else if(selectedCoupons.length===0){
      alert('please select Book')
    }
    else{
      setIssueCouponButton(true)
      axios.put(BaseUrl+`/updatedealercmstatus`,formattedCoupons)
      .then(response => {
        console.log(response.data)
        const RequestCouponData = { number:selectedCoupons.length , dealerid:DealerId};
        axios.post(BaseUrl + '/setdealerrequest_directissue', RequestCouponData)
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        console.log(error);
      })
        setSelectedCoupons([])
        toggleIsFinished()
        toast.success('Book issued successfully!', {
          position: 'top-center' // Set the position to top-center
        });
        setDealerId("default")
      })
      .catch(error=> {
          console.log(error);
      }).finally(() => {
        setIssueCouponButton(false);
      });
    }
  }
  //payment
  const [PaymentIssueButton, setPaymentIssueButton] = useState(false)
  const options = [
    { value: 'default', label: 'Select a Member', isDisabled: true },
    ...DealersData.map((data) => ({ value: data.Id, label: `${data.DealerNo} - ${data.Shopename}` })),
  ];
 
  function Payment() {
    console.log(PaymentDealerId);
    const paymentData = {
      dealerid: PaymentDealerId,
      pdate: PaymentDate,
      amount: PaymentAmount,
      paymode: 1,
      status: 2
    };
  
    if (PaymentDealerId === 'default' || PaymentAmount <= 0 || !Number.isInteger(PaymentAmount)) {
      if (PaymentDealerId === 'default') {
        alert('Please select a member');
      } else if (PaymentAmount <= 0 || !Number.isInteger(PaymentAmount)) {
        alert('Please enter a valid amount');
      }
    } else {
      setPaymentIssueButton(true);
      axios
        .post(BaseUrl + `/setpayment`, paymentData)
        .then((response) => {
          toast.success('payment successful!', {
            position: 'top-center' // Set the position to top-center
          });
          setPaymentAmount('');
          setPaymentDealerId('default'); // Reset to default value after successful payment
          setPaymentDate(new Date().toISOString().split('T')[0]);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          // Enable the button again after the request is completed
          setPaymentIssueButton(false);
        });
    }
  }
  
  //sidemenu
  //style
const styles = {
  brown: '#786450',
  frame: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '400px',
    height: '400px',
    marginTop: '-200px',
    marginLeft: '-200px',
    borderRadius: '2px',
    boxShadow: '1px 2px 10px 0px rgba(0,0,0,0.3)',
    background: '#CA7C4E',
    background: '-webkit-linear-gradient(bottom left, #EEBE6C 0%, #CA7C4E 100%)',
    background: '-moz-linear-gradient(bottom left, #EEBE6C 0%, #CA7C4E 100%)',
    background: '-o-linear-gradient(bottom left, #EEBE6C 0%, #CA7C4E 100%)',
    background: 'linear-gradient(to top right, #EEBE6C 0%, #CA7C4E 100%)',
    filter: 'progid:DXImageTransform.Microsoft.gradient(startColorstr="#EEBE6C", endColorstr="#CA7C4E", GradientType=1)',
    color: '#786450',
    fontFamily: "'Open Sans', Helvetica, sans-serif",
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  center: {
    position: 'absolute',
    top: '50px',
    left: '40px',
    height: '299px',
    width: '320px',
    background: '#fff',
    borderRadius: '3px',
    overflow: 'hidden',
    boxShadow: '10px 10px 15px 0 rgba(0,0,0,0.3)',
  },
  profile: {
    float: 'left',
    width: '200px',
    height: '320px',
    textAlign: 'center',
  },
  image: {
    position: 'relative',
    width: '70px',
    height: '70px',
    margin: '38px auto 0 auto',
  },
  circle1: {
    position: 'absolute',
    boxSizing: 'border-box',
    width: '76px',
    height: '76px',
    top: '-3px',
    left: '-3px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#786450 #786450 #786450 transparent',
    borderRadius: '50%',
    transition: 'all 1.5s ease-in-out',
  },
  circle2: {
    extend: 'circle1',
    width: '82px',
    height: '82px',
    top: '-6px',
    left: '-6px',
    borderColor: '#786450 transparent #786450 #786450',
  },
  img: {
    display: 'block',
  },
  name: {
    fontSize: '30px',
    fontWeight: '600',
    marginTop: '5px',
  },
  job: {
    fontSize: '15px',
    lineHeight: '15px',
  },
  actions: {
    marginTop: '33px',
  },
  btn: {
    display: 'block',
    width: '120px',
    height: '30px',
    margin: '0 auto 10px auto',
    background: 'none',
    border: '1px solid #786450',
    borderRadius: '15px',
    fontSize: '12px',
    fontWeight: '600',
    boxSizing: 'border-box',
    transition: 'all .3s ease-in-out',
    color: 'red',
  },
  stats: {
    float: 'left',
  },
  box: {
    boxSizing: 'border-box',
    width: '120px',
    height: '99px',
    background: '#F5E8DF',
    textAlign: 'center',
    paddingTop: '28px',
    transition: 'all .4s ease-in-out',
  },
  boxHover: {
    background: '#E1CFC2',
    cursor: 'pointer',
  },
  box2: {
    margin: '1px 0',
  },
  value: {
    fontSize: '18px',
    fontWeight: '600',
  },
  parameter: {
    fontSize: '11px',
  },
};
const [sidebarVisible, setSidebarVisible] = useState(false);
    
function handleToggleSidebar() {
  setSidebarVisible(!sidebarVisible);
}
//Logout
function Logout() {
  const confirmMessage = "Are you sure you want to logout?"

  if (window.confirm(confirmMessage)) {
  localStorage.removeItem('isLoggedIn');
  localStorage.removeItem('userRole');
  localStorage.removeItem('user')
  localStorage.removeItem('DistrictId')
  navigate('/')
  }
}
// Collection Report  
 const [fromDate, setFromDate] = useState(getYesterdayDate());
const [toDate, setToDate] = useState(getTodayDate());
const [CollectionReport, setCollectionReport] = useState([])
  // Function to get yesterday's date in the format "YYYY-MM-DD"
  function getYesterdayDate() {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    return yesterday.toISOString().split('T')[0];
  }

  // Function to get today's date in the format "YYYY-MM-DD"
  function getTodayDate() {
    return new Date().toISOString().split('T')[0];
  }
 
  useEffect(() => {
    axios.get(BaseUrl+`/GetCollectionReport?Id=${DistrictId}&fromDate=${fromDate}&toDate=${toDate}`)
    .then(response => {
      // console.log(response.data)
      setCollectionReport(response.data)

    })
    .catch(error => {
      console.log(error);
    });
  }, [fromDate,toDate])
  
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    return `${formattedDay}/${formattedMonth}/${year}`;
  }
  //Total collection
  const [totalAmount, setTotalAmount] = useState(0);
  useEffect(() => {
    calculateTotalAmount();
  }, [CollectionReport]);

  const calculateTotalAmount = () => {
    let sum = 0;
    CollectionReport.forEach((data) => {
      sum += data.Amount;
    });
    setTotalAmount(sum);
  };

  //payment Request to State
  const [ReqPaymentAmount, setReqPaymentAmount] = useState('')
  const [TransactionId, setTransactionId] = useState('')
  const [ReqPaymentDate, setReqPaymentDate] = useState(new Date().toISOString().split('T')[0])
  const [PaymentButton, setPaymentButton] = useState(false)
  function RequestPayment(){
    const paymentDetails = {
      dealerid: DistrictId,
      pdate: ReqPaymentDate,
      amount: ReqPaymentAmount,
      status: 0,
      paymode:3,
      transactionid:TransactionId
    };
    if (ReqPaymentAmount <= 0 || !Number.isInteger(ReqPaymentAmount)) {
      alert('Please enter a valid Amount');
    } else {
      setPaymentButton(true)
      axios.post(BaseUrl + '/setpayment', paymentDetails)
        .then(response => {
          toast.success('Payment request success!', {
            position: 'top-center' // Set the position to top-center
          });
          setReqPaymentAmount('')
          setPaymentDate(new Date().toISOString().split('T')[0])
          setTransactionId('')
        })
        .catch(error => {
          console.log(error);
        }).finally(() => {
          // Enable the button again after the request is completed
          setPaymentButton(false);
        });
    }
  }
  //Total Cash 
  const [TotalCashCollected, setTotalCashCollected] = useState(0)
  useEffect(() => {
    const ToDate=getTodayDate()
   const FromDate='2022-07-05'
    axios.get(BaseUrl+`/GetCollectionReport?Id=${DistrictId}&fromDate=${FromDate}&toDate=${ToDate}`)
    .then(response => {
      let sum = 0;
      response.data.forEach((data) => {
        sum += data.Amount;
      });
      setTotalCashCollected(sum);

    })
    .catch(error => {
      console.log(error);
    })
  }, [])
  //Coupon Report
const [CouponIssuedList, setCouponIssuedList] = useState([])
const [filteredData, setFilteredData] = useState([]); // To store the filtered data
const [searchQuery, setSearchQuery] = useState('');

useEffect(() => {
  // Fetch complete data only once when the component mounts
  axios
    .get(`${BaseUrl}/detailedreport?Id=${DistrictId}&no=${0}`)
    .then((res) => {
      if (res.data) {
        console.log(res.data);
        setCouponIssuedList(res.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}, []);

const handleSearch = (e) => {
  const searchValue = e.target.value.toLowerCase();
  setSearchQuery(searchValue);

  const filteredData = CouponIssuedList.filter((item) => {
    for (const key in item) {
      const value = item[key];
      if (typeof value === 'string' && value.toLowerCase().includes(searchValue)) {
        return true;
      } else if (typeof value === 'number' && value.toString().includes(searchValue)) {
        return true;
      }
    }
    return false;
  });
  setFilteredData(filteredData);
};

const dataToDisplay = searchQuery.length > 0 ? filteredData : CouponIssuedList;

  return (
<div className="bodybg cv-bodypadding">
<div className="header-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-5">
              <img src={Logo} className="cv-reg-taglahlogo" alt="SaveDate" />
            </div>
            <div className="col-lg-6 col-md-6 col-7">
              <button className="notification-bell" onClick={handleToggleSidebar}><i id="menubtn" className={`fas ${sidebarVisible ? 'fa-times' : 'fa-user-circle'}`}></i></button>
            </div>
          </div>
        </div>
      </div>
      {sidebarVisible && (
        <div className="right-sidebar videsidebar">
 <div style={styles.frame}>
      <div style={styles.center}>
        <div style={styles.profile}>
          <div style={styles.image}>
            <div style={styles.circle2}>
            <img
              src={Logo}
              width="70"
              height="60"
              alt="TDAAK"
              style={styles.img}
            />
            </div>
          </div>
          <div style={styles.name}>{DistrictName}</div>
          <div style={styles.actions}>
          <button style={{ ...styles.btn, color: 'white',background:'#dc1818' }} onClick={Logout}>
          <i class="fa-solid fa-right-from-bracket"  style={{ marginRight: '8px' }}></i>
            Logout</button>
          <button  style={{ ...styles.btn, color: 'white',backgroundColor:'#064185' }} onClick={()=>navigate('/member')} >
          <i class="fa-solid fa-right-left" style={{ marginRight: '8px' }}></i>
            Member Page</button>
          </div>
        </div>
        <div style={styles.stats}>
          <div style={styles.box}>
            <span style={styles.value}>-</span><br/>
            <span style={styles.parameter}>Commitment</span>
          </div>
          <div style={{ ...styles.box, ...styles.box2 }}>
            <span style={styles.value}>{DistrictCouponData.length}</span> <br/>
            <span style={styles.parameter}>Books in Hand</span>
          </div>
          <div style={styles.box}>
            <span style={styles.value}>{TotalCashCollected}</span><br/>
            <span style={styles.parameter}>Cash Collected</span>
          </div>
        </div>
      </div>
    </div>
</div>
      )}
  <Tab.Container activeKey={activeTab} onSelect={handleTabSelect} >
    <div className="savedate-sidebar">
      <Nav className="flex-column "  fill variant="pills" id="v-pills-tab" aria-orientation="vertical" >
        <Nav.Link  eventKey="A" style={{ textAlign: 'left' }}>  
        <span style={{backgroundImage: `url(${CouponsPng})`,backgroundSize: 'cover', backgroundPosition: 'center'}}></span>
        <h6>Approve Book Request</h6>
        </Nav.Link>

        <Nav.Link eventKey="B" style={{ textAlign: 'left' }}>  
        <span className="groom" style={{backgroundImage: `url(${WalletPng})`,backgroundSize: 'cover', backgroundPosition: 'center'}}></span>
        <h6>Approve Payment</h6>
        </Nav.Link>

        <Nav.Link eventKey="C" style={{ textAlign: 'left' }}>  
        <span className="appearance" style={{backgroundImage: `url(${GiftVoucherPng})`,backgroundSize: 'cover', backgroundPosition: 'center'}}></span>
        <h6>Request New Books</h6>
        </Nav.Link>

        <Nav.Link eventKey="D" style={{ textAlign: 'left' }}>  
        <span className="appearance" style={{backgroundImage: `url(${CouponIssue})`,backgroundSize: 'cover', backgroundPosition: 'center'}}></span>
        <h6>Issue Books</h6>
        </Nav.Link>

        <Nav.Link eventKey="E" style={{ textAlign: 'left' }}>  
        <span className="appearance" style={{backgroundImage: `url(${cashPaymentPng})`,backgroundSize: 'cover', backgroundPosition: 'center'}}></span>
        <h6>Issue Cash Payment</h6>
        </Nav.Link>
        <Nav.Link eventKey="G" style={{ textAlign: 'left' }}>  
        <span className="appearance" style={{backgroundImage: `url(${PaymentPng})`,backgroundSize: 'cover', backgroundPosition: 'center'}}></span>
        <h6>Payment Intimation</h6>
        </Nav.Link>
        
        <Nav.Link eventKey="H" style={{ textAlign: 'left' }}>  
        <span className="appearance" style={{backgroundImage: `url(${ReportPng})`,backgroundSize: 'cover', backgroundPosition: 'center'}}></span>
        <h6>Books Report</h6>
        </Nav.Link>

        <Nav.Link eventKey="F" style={{ textAlign: 'left' }}>  
        <span className="appearance" style={{backgroundImage: `url(${ReportPng})`,backgroundSize: 'cover', backgroundPosition: 'center'}}></span>
        <h6>Collection Report</h6>
        </Nav.Link>

        <Nav.Link eventKey="I" style={{ textAlign: 'left' }}>  
        <span className="appearance" style={{backgroundImage: `url(${ReportPng})`,backgroundSize: 'cover', backgroundPosition: 'center'}}></span>
        <h6>Member Payment Ledger</h6>
        </Nav.Link>
        <Nav.Link eventKey="J" style={{ textAlign: 'left' }}>  
        <span className="appearance" style={{backgroundImage: `url(${ReportPng})`,backgroundSize: 'cover', backgroundPosition: 'center'}}></span>
        <h6>District Payment Ledger</h6>
        </Nav.Link>

      </Nav>
      </div>
      <div className="container-fluid">
      <Tab.Content id="v-pills-tabContent" style={{marginTop:'70px'}}>
        <Tab.Pane eventKey="A">
        <RequstedCouponDistrict DistrictId={DistrictId} DistrictCouponData={DistrictCouponData} from={'District'} couponDataRefresh={toggleIsFinished} TotalCashCollected={TotalCashCollected}/>
        </Tab.Pane>
        <Tab.Pane eventKey="B">
        <PaymentRequestDistrict PaymentRequestData={PaymentRequestData} from={'State'} toggleIsFinishedPayment={toggleIsFinishedPayment}/>
        </Tab.Pane>
        <Tab.Pane eventKey="C">
        <div className="col-lg-12 col-md-12">
         <div  style={{ background: 'rgb(245, 232, 223)', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '300px', border: '5px solid #92580a',borderRadius:'20px'}}>
         <div style={{padding:'10px'}}>
         <h4>
                Request New Books
         </h4>
         </div>
        <input
        type="number"
        placeholder="Enter number of Books"
        style={{ width: '250px',minHeight:'40px' ,textAlign: 'center',borderRadius:'20px'}}
        value={CouponNumber}
        onChange={(e) => setCouponNumber(parseFloat(e.target.value))}/>
               <div  style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
              <button className="issue-button" style={{width:'115px',height:'40px'}} disabled={ReqBookButton} onClick={Request}> {ReqBookButton ? 'Processing...' : 'Request'}</button>
            </div>
       </div>
         </div>
        </Tab.Pane>  
        <Tab.Pane eventKey="D">
            <div className="col-12" style={{ backgroundColor:'rgb(245, 232, 223)',padding: '10px', border: '5px solid #92580a',borderRadius:'20px'}}>
              <div className='col-12' style={{padding:'10px'}}>
            <input
            type="text"
            value={inputNumber}
            onChange={(e) => setInputNumber(e.target.value)}
            placeholder="Enter numbers or range"
            style={{width:'70%', fontSize: '18px'}}
          />
           <button type="submit" className='issue-button' style={{marginLeft:'10px'}} onClick={() => handleCouponSelection(inputNumber)}>Select</button>
           </div>
              <div className="district-coupon-selector">
                <div className="district-coupon-list">
                {DistrictCouponData.map((coupon) => (
          <button
           key={coupon.Number}
          className={selectedCoupons.includes(coupon.Number) ? 'selected' : ''}
          onClick={() => handleCouponSelection(coupon.Number)}
           style={{ position: 'relative' }}
            >
    <div style={{ textAlign: 'center' }}>
      {coupon.Number}
    </div>
    <div
      style={{
        fontSize: '8px',
        color:'rgba(0, 0, 0, 0.8)',
        position: 'absolute',
        bottom: '5px',
        left: '5px',
        right: '5px',
        textAlign: 'center',
      }}
    >
      ({coupon.Number * 100 - 99}-{coupon.Number * 100})
    </div>
  </button>
))}
                </div>
              </div>
              <div className="container CouponFooter">
              <div className='col-lg-4 d-flex justify-content-center' style={{padding:'10px'}}>
    <h5>books:{selectedCoupons.length}</h5>
  </div>
  <div className="col-lg-6 d-flex justify-content-center dropdown-container">
    {/* <select  value={DealerId} onChange={(e) => setDealerId(e.target.value)} style={{ width: '250px',height:'40px', textAlign: 'center',borderRadius:'20px' }}>
      <option value="default" disabled>Select a Member</option>
      {DealersData.map((data)=>(<option key={data.Id} value={data.Id}>{`${data.DealerNo} - ${data.Shopename}`}</option>))}
    </select> */}
           <Select
      value={options.find((option) => option.value === DealerId)}
      onChange={(selectedOption) => setDealerId(selectedOption.value)}
      options={options}
      styles={{
        control: (provided) => ({
          ...provided,
          width: '250px',
          height: '40px',
          textAlign: 'center',
          borderRadius: '20px',
          margin: '0 auto',
        }),
      }}
    />
  </div>
     <div className="col-lg-2 d-flex justify-content-center button-container">
    <button className="issue-button" onClick={IssueCoupon} disabled={IssueCouponButton} >{IssueCouponButton? 'Processing...' : 'Issue'}</button>
  </div>
    </div>
              </div>
        </Tab.Pane> 
        <Tab.Pane eventKey="E">
        <div className='col-lg-12 col-md-12'>
  <div className='subscription' style={{border: '5px solid #92580a',borderRadius:'20px'}}>
    <div className='row'>
      <h5>Payment</h5>
    </div>
    <div className="row" style={{ display: 'flex', justifyContent: 'center', padding:'10px' }}>
      {/* <select value={PaymentDealerId} onChange={(e) => setPaymentDealerId(e.target.value)} style={{ width: '250px', height:'40px', textAlign: 'center',borderRadius:'20px'}}>
        <option value="default" disabled>Select a Member</option>
        {DealersData.map((data) => (<option key={data.Id} value={data.Id}>{`${data.DealerNo} - ${data.Shopename}`}</option>))}
      </select> */}
       <Select
      value={options.find((option) => option.value === PaymentDealerId)}
      onChange={(selectedOption) => setPaymentDealerId(selectedOption.value)}
      options={options}
      styles={{
        control: (provided) => ({
          ...provided,
          width: '250px',
          height: '40px',
          textAlign: 'center',
          borderRadius: '20px',
          margin: '0 auto',
        }),
      }}
    />
    </div>
    <div className='row' style={{ display: 'flex', justifyContent: 'center', padding:'10px' }}>
      <input type="number" placeholder="Enter Amount" style={{ width: '250px',height:'40px', textAlign: 'center',borderRadius:'20px' }} value={PaymentAmount}    onChange={(e) => setPaymentAmount(parseFloat(e.target.value))}/>
    </div>
    <div className="row" style={{ display: 'flex', justifyContent: 'center', padding:'10px' }}>
      <input type="date" style={{ width: '250px', textAlign: 'center' ,height:'40px', padding:'10px',borderRadius:'20px' }} value={PaymentDate} onChange={(e) => setPaymentDate(e.target.value)} />
    </div>
    <div className='row' style={{ display: 'flex', justifyContent: 'center', padding:'10px'}}>
      <button className="issue-button" onClick={Payment} disabled={PaymentIssueButton}>{PaymentIssueButton? 'Processing...' : 'Submit'}</button>
    </div>
  </div>
</div>
        </Tab.Pane> 
        <Tab.Pane eventKey="F">
        <div className='row mb-4'>
      <div className='col-lg-5 col-6'>
        <label style={{ fontWeight: '700' }}>From:</label>
        <input
          type='date'
          className='form-control'
          style={{ width: '100%' }}
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
        />
      </div>
      <div className='col-lg-5 col-6'>
        <label style={{ fontWeight: '700' }}>To:</label>
        <input
          type='date'
          className='form-control'
          style={{ width: '100%' }}
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
        />
      </div>
      <div className='col-lg-2' >
  <label style={{ fontWeight: '600' }}>Total:</label>
  <div style={{ background: 'white', border: '1px solid grey', width: '75%', height: '35px', maxWidth: '165px' }}>
  <label style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%',fontSize:'20px',fontWeight:'700',color:'green' }}>₹{totalAmount}</label>
  </div>
</div>
    </div>
      <div className="col-12">
      <table className="table table-bordered table-responsive">
  <thead style={{ background: '#064185', color: 'White' }}>
    <tr>
      {/* <th className="text-center">Sl.No</th> */}
      <th className="text-center">Date</th>
      <th className="text-center">Member Code</th>
      {/* <th className="text-center">Member Name</th> */}
      <th className="text-center">Collected Amount</th>
    </tr>
  </thead>
  <tbody>
    {CollectionReport.length === 0 ? (
      <tr>
        <td colSpan="3" className="text-center">
          No data available
        </td>
      </tr>
    ) : (
      CollectionReport.map((data, index) => (
        <tr className="text-center" key={index}>
          {/* <td data-label="slno">{index+1}</td> */}
          <td data-label="Date">{formatDate(data.PaymentDate)}</td>
          <td>{` ${data.DealerNo}- ${data.ShopName}`}</td>
          {/* <td data-label="Dealer Name">{data.Name}</td> */}
          <td data-label="Collected Amount">₹{data.Amount}</td>
        </tr>
      ))
    )}
  </tbody>
</table>

      </div>
        </Tab.Pane>

        <Tab.Pane eventKey="H">
        <div className='row mb-4'>
      <div className='col-lg-5 col-6'>
        {/* <label style={{ fontWeight: '700' }}>From:</label> */}
        <input
          type='text'
          className='form-control'
          style={{ width: '100%' }}
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearch}
        />
      </div>
    </div>
        <div className="col-12">
        <table className="table table-bordered table-responsive">
  <thead style={{ background: '#064185', color: 'White' }}>
    <tr>
      <th className="text-center">Issued date</th>
      <th className="text-center">Member Code</th>
      <th className="text-center">Book Number</th>
    </tr>
  </thead>
  <tbody>
    {dataToDisplay.length === 0 ? (
      <tr>
        <td colSpan="3" className="text-center">
          No data available
        </td>
      </tr>
    ) : (
      dataToDisplay.map((data, index) => (
        <tr className="text-center" key={index}>
          <td data-label="Date">{formatDate(data.IssueDate)}</td>
          <td data-label="Dealer Name">{`${data.SubDealerNo} - ${data.ShopeName}`}</td>
          <td data-label="Collected Amount">{data.Number}</td>
        </tr>
      ))
    )}
  </tbody>
</table>

      </div>
        </Tab.Pane>

        <Tab.Pane eventKey="G">
        <div className="requestcoupon" style={{ border: '5px solid #92580a',borderRadius:'20px'}}>
            <div className="row" style={{padding:'10px'}}>
              <h4>
                Payment Intimation
              </h4>
            </div>
            <div className="row" style={{ display: 'flex', justifyContent: 'center',padding:'10px' }}>
           <input type="date" style={{ width: '250px',minHeight:'40px' ,textAlign: 'center',borderRadius:'20px' }} value={ReqPaymentDate} onChange={(e) => setReqPaymentDate(e.target.value)} />
            </div>
            <div className="row" style={{ display: 'flex', justifyContent: 'center',padding:'10px' }}>
              <input
                type="number"
                value={ReqPaymentAmount}
                placeholder="Enter Amount(₹)"
                style={{ width: '250px',minHeight:'40px' ,textAlign: 'center',borderRadius:'20px' }}
                onChange={(e) => setReqPaymentAmount(parseFloat(e.target.value))}
              /> 
            </div>
            <div className="row" style={{ display: 'flex', justifyContent: 'center',padding:'10px' }}>
              <input
                type="text"
                value={TransactionId}
                placeholder="Transaction id or Bank Name"
                style={{ width: '250px',minHeight:'40px' ,textAlign: 'center',borderRadius:'20px' }}
                onChange={(e) => setTransactionId(e.target.value)}
              /> 
            </div>
            <div className="row" style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
              <button className="issue-button" style={{width:'115px',height:'40px'}} disabled={PaymentButton}  onClick={RequestPayment}>{PaymentButton? 'Processing...' : 'Intimate'}</button>
            </div>
          </div>
        </Tab.Pane>

        <Tab.Pane eventKey="I">
        <MemberPaymenReportDistrict DealersData={DealersData}/>
        </Tab.Pane>
        <Tab.Pane eventKey="J">
        <DistrictPaymentHistory DistrictId={DistrictId} TotalCashCollected={TotalCashCollected} BookInHand={DistrictCouponData.length}/>
        </Tab.Pane>
      </Tab.Content>
      </div>
    </Tab.Container>
</div>
  )
}

export default DistrictPageWithSideMenu