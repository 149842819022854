import React, { useState } from 'react';
import '../CSS/Login.css'
import axios from 'axios';
import { BaseUrl } from '../Constants/BaseUrl';
import { useNavigate } from "react-router-dom"
function Login() {
  const navigate=useNavigate()
  const [Email, setEmail] = useState('');
  const [OTP, setOTP] = useState('')
  const [IsLogin, setIsLogin] = useState(true)
  const [IsOtp, setIsOtp] = useState(false)
  const handleToggleControl=(val)=>{
    if(val===1){
        setIsLogin(true)
        setIsOtp(false)
    }
    else if(val===2){
      setIsLogin(false)
      setIsOtp(true)
    }
}
const GenerateOTP=()=>{
  const LoginData={email:Email}
  axios.post(BaseUrl+`/getotp`,LoginData)
  .then(response => {
  console.log(response.data)
  if(response.data==='Success'){
    handleToggleControl(2)
  }else{
    alert('Error')
  }
  })
  .catch(error => {
    console.log(error);
  });
}
const UserLogin=()=>{
  const LoginData={email:Email,otp:OTP}
  axios.post(BaseUrl+`/login`,LoginData)
  .then(response => {
  if(response.data){
    if(response.data[0].UserTypeId==='CA8E85F3-16C4-40CC-8A5D-1CC9A406A2D9'){
      const data=response.data[0]
      navigate('/member',{ state: data})
    }
    else if(response.data[0].UserTypeId==='F4688F9E-702F-4147-A44A-99866FE9124A'){
      const data=response.data[0]
      navigate('/district',{ state: data})
    }else if(response.data[0].UserTypeId==='BC763517-EE09-4DFB-8571-64E5E3EF7B64'){
      const data=response.data[0]
      navigate('/statecommitte',{ state: data})
    }
    else{
      alert('user not found')
    }
  }else{
    alert('login failed')
  }
  })
  .catch(error => {
    console.log(error);
  });
}
  return (
    <>
   {IsLogin&&<div class="container create-formcon">
      <div class="row justify-content-center" style={{ maxHeight: '444px' }}>
        <div class="col-lg-4 col-md-7 login-otp-height">
          <div class="loginotp-holder">
          <h3>Login</h3>
            <p>Please enter your Email</p>
            <div class="login-form-item">
              <i class="far fa-envelope"></i>
              <input
                type="email"
                class="form-control"
                placeholder="Enter Email"
                value={Email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div class="row justify-content-center">
              <div class="col-lg-6">
                <button class="create-btns" onClick={GenerateOTP}>
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>}
  {IsOtp&& <div class="container create-formcon">
    <div class="row justify-content-center" style={{ maxHeight: '444px' }}>
        <div class="col-lg-4 col-md-7 login-otp-height">
            <div class="loginotp-holder">
                <h3>Enter OTP</h3>
                <p>We have send OTP to your email. If you have not received OTP in your Inbox, Please check spam /
                    junk folder.</p>
                <div class="login-form-item">
                    <i class="fas fa-dot-circle"></i>
                    <input type="text" class="form-control" placeholder="Enter Code"  onChange={(e)=>setOTP(e.target.value)}/>
                </div>

                <div class="row justify-content-center">
                    <div class="col-lg-6">
                        <button class="create-btns" onClick={UserLogin}>Continue</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>}
</>
  );
}

export default Login;
