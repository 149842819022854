import axios from 'axios';
import React,{useState,useEffect} from 'react'
import { BaseUrl } from '../Constants/BaseUrl';
function CouponHistoryDealer(props) {
  const [CouponHistoryData, setCouponHistoryData] = useState([])
  function getStatusText(status,ApprovedCoupons) {
    switch (status) {
      case 0:
        return 'Pending..';
      case 1:
        return `${ApprovedCoupons} Books Approved`;
      case 2:
        return 'Rejected';
      default:
        return 'Unknown';
    }
  }
  function getStatusColor(status) {
    switch (status) {
      case 0:
        return 'blue';
      case 1:
        return 'green';
      case 2:
        return 'red';
      default:
        return 'black'; // Default color if status is unknown or not found in switch cases
    }
  }
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    return `${formattedDay}/${formattedMonth}/${year}`;
  }
 useEffect(() => {
  axios.get(`${BaseUrl}/getalldealerreq?Id=${props.DealerId}`)
  .then((res) => {
      setCouponHistoryData(res.data)
      console.log(res.data)
  })
  .catch((error) => console.log(error));
 }, [props.DealerId])
 
  return (
    <div className="col-lg-12">
    <div className="text-center">
      <h3 style={{ padding: '10px' }}>Book History</h3>
    </div>
    <table className="table table-bordered table-responsive">
      <thead style={{ background: '#064185', color: 'White' }}>
        <tr>
          <th className="text-center">Date</th>
          <th className="text-center">Requested Books</th>
          <th className="text-center">Status</th>
        </tr>
      </thead>
      <tbody>
        {CouponHistoryData.length === 0 ? (
          <tr>
            <td className="text-center" colSpan="3">
              No data available
            </td>
          </tr>
        ) : (
          CouponHistoryData.map((data, index) => (
            <tr key={index}>
              <td className="text-center">{formatDate(data.RequestDate)}</td>
              <td className="text-center" style={{ fontWeight: '700' }}>
                {data.NoOfCoupons}
              </td>
              <td
                className="text-center"
                style={{
                  color: getStatusColor(data.Status),
                  fontWeight: '700',
                }}
              >
                {getStatusText(data.Status, data.ApprovedCoupon)}
              </td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  </div>
  )
}

export default CouponHistoryDealer