import React,{useState,useEffect} from 'react';
import { BaseUrl } from '../Constants/BaseUrl';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RejectReasonModal from './RejectReasonModal';
function PaymentRequestDistrict(props) {
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    return `${formattedDay}/${formattedMonth}/${year}`;
  }
const [ApproveButton, setApproveButton] = useState(false)
  function ApprovePayment(Id,Amount,dealerId) {
    // console.log(dealerId)
    setApproveButton(true)
    axios.post(`${BaseUrl}/updatepaystatus?pid=${Id}&status=2&amount=${Amount}&did=${dealerId}&remarks=''`)
      .then(response => {
        props.toggleIsFinishedPayment()
        toast.success('Request successful!', {
          position: 'top-center' // Set the position to top-center
        });
      })
      .catch(error => {
        console.log(error)
      }).finally(() => {
        setApproveButton(false)
      });
  }
  
  function RejectPayment(Id,Amount,dealerId,remarks) {
    const confirmMessage = "Are you sure you want to reject this request?"
    axios.post(`${BaseUrl}/updatepaystatus?pid=${Id}&status=1&amount=${Amount}&did=${dealerId}&remarks=${remarks}`)
      .then(response => {
        props.toggleIsFinishedPayment()
      })
      .catch(error => 
        {
        console.log(error)
      })
  }
//Remark modal
const [feedbackModal, setFeedbackModal] = useState(false);
const openFeedbackModal = (Id,Amount,dealerId) => {
  setFeedbackModal(true)
};

const closeFeedbackModal = () => {
  setFeedbackModal(false);
};
const [RejectData, setRejectData] = useState({
  Id:'',
  Amount:0,
  dealerId:''

})
function RejectPaymentModal(Id, Amount, dealerId) {
  setRejectData({ Id, Amount, dealerId });
  openFeedbackModal()
}

  return (
    <>
     {props.PaymentRequestData.length === 0 ? (
        <div className="text-center">
          <h3>No data available</h3>
        </div>
      ) : (
    <div className='row my-table-container'>
      <div className="col-12">
        <table className="table">
          <thead>
            <tr>
            <th>Date</th>
              {props.From !== 1 && <th>Member Code</th>}
              <th>Member</th>
              <th>Amount</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {props.PaymentRequestData.map((data) => (
              <tr key={data.Id}>
                <td data-label="Date">{formatDate(data.PaymentDate)}</td>
                {props.From !== 1 && <td data-label="Dealer Code">{`${data.Dealercode} - ${data.ShopName}`}</td>}
                <td data-label="Dealer">{data.dealername}</td>
                <td data-label="Amount">₹{data.Amount}</td>
                <td data-label="Status">
                  <button
                    className="issue-button"
                    onClick={() => ApprovePayment(data.Id,data.Amount,data.Dealerid)}
                    disabled={ApproveButton}
                  >
                   {ApproveButton? 'wait..' : 'Approve'}
                  </button>{" "}
                  <button
                    className="issue-button"
                    onClick={() => RejectPaymentModal(data.Id,data.Amount,data.Dealerid)}
                    style={{
                      color: "black",
                      backgroundColor: "white",
                      border: "1px solid red",
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.color = "white";
                      e.target.style.backgroundColor = "red";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.color = "black";
                      e.target.style.backgroundColor = "white";
                    }}
                  >
                    Reject
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>)}
<RejectReasonModal feedbackModal={feedbackModal} closeFeedbackModal={closeFeedbackModal} RejectData={RejectData} RejectPayment={RejectPayment}/>

    </>
  );
}

export default PaymentRequestDistrict;
