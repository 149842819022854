import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Label,Cell } from 'recharts';
import axios from 'axios';
import { BaseUrl } from '../Constants/BaseUrl';
function DistrictCountBar() {
 const [sdate, setsdate] = useState('');
const [edate, setedate] = useState('');
const [data, setdata] = useState([]);
useEffect(() => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;

  const startOfMonth = new Date(year, month - 1, 1);
  const endOfMonth = new Date(year, month, 0);

  const formattedStartDate = startOfMonth.toISOString().substring(0, 10);
  const formattedEndDate = endOfMonth.toISOString().substring(0, 10);

  setsdate(formattedStartDate);
  setedate(formattedEndDate);
  axios
      .get(BaseUrl+ '/getdistrictcount', { params: { sdate: formattedStartDate, edate: formattedEndDate } })
      .then((response) => {
        if (response.data) {
            // console.log("response"+response.data)
          setdata(response.data);
          // console.log("datalist"+data)
          data.map((d)=>{
            // console.log(d)
          })

        }
      })
}, []);
const Getdatas=()=>{
    axios
      .get(BaseUrl + '/getdistrictcount', { params: { sdate: sdate, edate: edate } })
      .then((response) => {
        if (response.data) {
            // console.log("response"+response.data)
          setdata(response.data);
          // console.log("datalist"+data)
          data.map((d)=>{
            // console.log(d)
          })

        }
      })
      .catch((error) => {
        console.log(error);
      });
    }


    const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

// Calculate the total count
const totalCount = data.reduce((total, entry) => total + entry.TotalCount, 0);

// Calculate the percentage for each entry
const dataWithPercentage = data.map((entry) => ({
...entry,
percentage: (entry.TotalCount / totalCount) * 100,
}));
const currentDate = new Date().toISOString().split('T')[0];

  return (
    
  <div className="container">
  <span className="glyphicon glyphicon-calendar"> <h5 >TDAAC-Districts Wise Book Count Report</h5></span>
       <div className="row justify-content-center">
      <div></div>
       <div className="col-sm-6 col-md-4">
         <div className="input-group mb-3">
           <input
             type="date"
             className="form-control"
             value={sdate }
             onChange={(e) => setsdate(e.target.value)}
             
           />
           <span className="input-group-addon">
            
           </span>
         </div>
       </div>
       <div className="col-sm-6 col-md-4">
         <div className="input-group mb-3">
           <input
             type="date"
             className="form-control"
             value={edate }
             onChange={(e) => setedate(e.target.value)}
           />
           <span className="input-group-addon">
             <span className="glyphicon glyphicon-calendar"></span>
           </span>
         </div>
       </div>
       <div className="col-md-2">
         <button className="issue-button" onClick={Getdatas} >
           Show
         </button>
       </div>
       </div>
       <div className="row" style={{ marginLeft: '10px' }}>
        <div className="col-lg-12 col-md-6 col-6">
          <div >
            <BarChart width={500} height={400} data={dataWithPercentage}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="DistrictName">
                <Label value="District" offset={0} position="insideBottom" />
              </XAxis>
              <YAxis>
                <Label value="Count" angle={-90} offset={10} position="insideLeft" />
              </YAxis>
              <Tooltip />
              <Bar dataKey="TotalCount" fill="#8884d8" />
            </BarChart>

          { /* <BarChart width={400} height={400} data={data}>
            <XAxis dataKey="name" />
            <Bar dataKey="TotalCount">
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
              ))}
            </Bar>
          </BarChart>
          */}
          </div>
       
        </div>
        </div>
        </div>  

  )
}

export default DistrictCountBar