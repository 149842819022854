import React from 'react'
import { Modal,Button} from 'react-bootstrap';
import PrivacyPolicyPage from '../Pages/PrivacyPolicyPage';
import { useNavigate } from 'react-router-dom';
function PrivacyPolicy(props) {
  const navigate = useNavigate();

  const handleClick = () => {
    // Navigate to the desired page
    navigate('/privacy-policy');
  };
  return (
    <Modal
    show={props.showPrivacy}
    onHide={props.handlePrivacyClose}
    className='terms-modal-wrap'
    size="lg"
    aria-labelledby="example-modal-sizes-title-lg"
  >
    <Modal.Header closeButton>
      <Modal.Title><h5 className="modal-title" onClick={handleClick}>Privacy Policy</h5></Modal.Title>
    </Modal.Header>
    <Modal.Body className="terms-container">
    <PrivacyPolicyPage/>
    </Modal.Body>
  </Modal>
  )
}

export default PrivacyPolicy