import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BaseUrl } from '../Constants/BaseUrl';
import CouponIssueDistrict from './CouponIssueDistrict';
import '../CSS/RequestedCouponDistrict.css';

function RequestedCouponDistrict(props) {
  const [RequstData, setRequstData] = useState([]);
  const [IsFinished, setIsFinished] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [DealerDetails, setDealerDetails] = useState({
    Name: '',
    Number: 0,
    Id: '',
    Code: '',
    RequestDate: '',
  });

  const handleModalClose = () => {
    setShowModal(false);
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    return `${formattedDay}/${formattedMonth}/${year}`;
  }

  const handleButtonClick = (Name, Number, Id, Code, RequestDate) => {
    setDealerDetails({
      Name: Name,
      Number: Number,
      Id: Id,
      Code: Code,
      RequestDate: RequestDate,
    });
    setShowModal(true);
  };

  const toggleIsFinished = () => {
    setIsFinished(!IsFinished);
  };

  useEffect(() => {
    console.log(props.DistrictId)
    axios
      .get(BaseUrl + `/getdealerreq?Id=${props.DistrictId}`)
      .then((response) => {
        console.log(response.data)
        setRequstData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [IsFinished]);
// console.log(RequstData)
  const Reject = (Id, Date,Number) => {
    const confirmMessage = 'Are you sure you want to reject this request?';

    if (window.confirm(confirmMessage)) {
      axios
        .delete(BaseUrl + `/rejectreq?did=${Id}&date=${Date}&rejno=${Number}`)
        .then((response) => {
          toggleIsFinished();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
//style
const styles = {
  brown: '#786450',
  rowStyles: {
    '--bs-gutter-x': 'initial' // Set the property to 'initial' to remove it
  }
};
  return (
    <>
      <CouponIssueDistrict
        showModal={showModal}
        onClose={handleModalClose}
        toggleIsFinished={toggleIsFinished}
        DealerDetails={DealerDetails}
        DistrictCouponData={props.DistrictCouponData}
        couponDataRefresh={props.couponDataRefresh}
      />
      {RequstData.length === 0 ? (
        <div className="text-center">
          <h3>No data available</h3>
        </div>
      ) : (
      <div className="row my-table-container">
      <div className="row" style={styles.rowStyles}>
  <div className="col-4 mb-4">
    <div className="card" >
      <span className="big">{props.DistrictCouponData.length}</span>
      <span className="small">Books in Hand</span>
    </div>
  </div>
  <div className="col-4 mb-4">
    <div className="card">
      <span className="big" >₹{props.TotalCashCollected}</span>
      <span className="small">Cash Collected</span>
    </div>
  </div>
  <div className="col-4 mb-4">
    <div className="card">
      <span className="big">-</span>
      <span className="small">Commitment</span>
    </div>
  </div>
        </div>
        <div className="col-12 ">
          <table className="table">
            <thead>
              <tr>
              <th>Date</th>
                <th>Member Code</th>
                <th>Member</th>
                <th>Books requested</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {RequstData.map((data, index) => (
                <tr key={index}>
                  <td data-label="Date">{formatDate(data.RequestDate)}</td>
                  <td data-label="Dealer Code">{`${data.Dcode} - ${data.ShopName}`}</td>
                  <td data-label="Dealer">{data.Dealer}</td>
                  <td data-label="Coupons requested">{data.NoOfCoupons}</td>
                  <td data-label="Status">
                    <button
                      className="issue-button"
                      onClick={() =>
                        handleButtonClick(
                          data.Dealer,
                          data.NoOfCoupons,
                          data.DealerId,
                          data.Dcode,
                          data.RequestDate
                        )
                      }
                    >
                      Issue
                    </button>{" "}
                    <button
                      className="issue-button"
                      style={{
                        color: 'black',
                        backgroundColor: 'white',
                        border: '1px solid red',
                      }}
                      onMouseEnter={(e) => {
                        e.target.style.color = 'white';
                        e.target.style.backgroundColor = 'red';
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.color = 'black';
                        e.target.style.backgroundColor = 'white';
                      }}
                      onClick={() => Reject(data.DealerId, data.RequestDate,data.NoOfCoupons)}
                    >
                      Reject
                    </button>
                  </td>
                </tr>
              ))}
              {/* <tr style={{ textAlign: "end", fontWeight: "bold", backgroundColor: "#f8f9fa" }}>
                <td className="text-center"></td>
                <td className="text-center">Total coupons requested</td>
                <td className="text-center">{RequstData.reduce((total, data) => total + data.NoOfCoupons, 0)}</td>
                <td className="text-center"></td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </div>)}
    </>
  );
}

export default RequestedCouponDistrict;
